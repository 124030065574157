import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Button, Form, Input, Card, Divider, Select, Space } from 'antd';
import Icon, { EuroOutlined } from '@ant-design/icons';
import { ReactComponent as AlertIcon } from 'assets/img/icons/alert.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getDebtTypes } from '../../../../store/entities/administration/thunks';
import { IncreaseDecreaseDebtModal } from '../modals';
import LoadingSpinner from '../../../../components/reusable/LoadingSpinner';

export const SelectedDebtorSection = memo(({ companyOrPerson }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { debtTypes, debtTypesLoading, selectedDebtor, companyOrPersonDebtsLoading } = useSelector(
    state => state.administration
  );

  useEffect(() => {
    dispatch(getDebtTypes({ companyOrPerson }));
  }, []);

  useEffect(() => {
    if (selectedDebtor) {
      form.setFieldsValue(selectedDebtor);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectedDebtor, form]);

  if (!selectedDebtor) return null;

  return (
    <LoadingSpinner size="small" spinning={companyOrPersonDebtsLoading}>
      <Row justify="start">
        <Col>
          <Space size="large">
            <Icon component={AlertIcon} className="icon--gray" />
            <span>{t('common.debt')}</span>
          </Space>
        </Col>
      </Row>
      <Form
        form={form}
        initialValues={selectedDebtor ?? {}}
        labelCol={{ span: 24 }}
        className="new-debt"
        requiredMark={false}
      >
        <Card className="space-vertical-lg has-shadow">
          <span>{t('administration.debtManagement.company.newDebt.information.title')}</span>
          <Divider />
          <Row
            gutter={[16, 16]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col xs={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item name="debtId" label={t('administration.debtManagement.company.newDebt.information.debtId')}>
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="amount"
                label={t('administration.debtManagement.company.newDebt.information.amount')}
                extra={t('administration.debtManagement.company.newDebt.information.amount.subtitle')}
              >
                <Input addonAfter={<EuroOutlined />} readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="submissionDate"
                label={t('administration.debtManagement.company.newDebt.information.submission.date')}
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="originalAmount"
                label={t('common.originalAmount')}
                extra={t('administration.debtManagement.company.newDebt.information.amount.subtitle')}
              >
                <Input addonAfter={<EuroOutlined />} readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="dateIncurrence"
                label={t('administration.debtManagement.company.newDebt.information.incurrence.date')}
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="debtClaimType"
                label={t('administration.debtManagement.company.newDebt.information.type')}
              >
                <Select
                  placeholder={t('administration.debtManagement.company.newDebt.information.type.placeholder')}
                  loading={debtTypesLoading}
                  disabled
                  name="debtType"
                  defaultValue={debtTypes.length && selectedDebtor.debtType}
                >
                  {!!debtTypes.length &&
                    debtTypes.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <span>{t('administration.debtManagement.company.newDebt.debtor.title')}</span>
          <Divider />
          <Row>
            <Col xs={24}>
              <Form.Item name="companyName" label={t('administration.debtManagement.company.newDebt.debtor')}>
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col xs={24} md={11}>
              <Form.Item name="companyCode" label={t('administration.debtManagement.company.newDebt.debtor.code')}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item name="debtorHead" label={t('administration.debtManagement.company.newDebt.debtor.manager')}>
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col xs={24} md={11}>
              <Form.Item name="phoneNumber" label={t('administration.debtManagement.company.newDebt.debtor.phone')}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item name="address" label={t('administration.debtManagement.company.newDebt.debtor.address')}>
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[0, 20]} style={{ marginTop: 20 }}>
            <Col xs={24} xxl={21}>
              <Row gutter={[0, 16]}>
                <Col xs={24} xxl={8}>
                  <Button
                    onClick={() => dispatch({ type: 'TOGGLE_COVERED_PARTIALLY_COVERED_MODAL', payload: true })}
                    type="default"
                    size="small"
                    className="btn-sm-text-left"
                    block
                  >
                    {t('common.coveredOrPartially')}
                  </Button>
                </Col>

                <Col xs={24} xxl={8}>
                  <Button
                    onClick={() => dispatch({ type: 'TOGGLE_INCREASE_DEBT_MODAL', payload: true })}
                    type="default"
                    size="small"
                    className="btn-sm-text-left"
                    block
                  >
                    {t('common.increaseDebt')}
                  </Button>
                </Col>

                <Col xs={24} xxl={8}>
                  <Button
                    onClick={() => dispatch({ type: 'TOGGLE_INFORM_ABOUT_INCORRECT_DEBT_MODAL', payload: true })}
                    type="default"
                    size="small"
                    className="btn-sm-text-left"
                    block
                  >
                    {t('common.informAboutIncorrectDebt')}
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col xs={24} xxl={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
              <Button onClick={() => dispatch({ type: 'REMOVE_SELECTED_DEBTOR' })} className="save">
                {t('global.return')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
      <IncreaseDecreaseDebtModal companyOrPerson={companyOrPerson} />
    </LoadingSpinner>
  );
});
