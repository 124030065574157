import { createSelector } from 'reselect';
import _pick from 'lodash.pick';
import isAfter from 'date-fns/isAfter';
import subYears from 'date-fns/subYears';

import {
  createEmployeesTable,
  createVehicleFleetTable,
  createVehiclesTable,
  unifyCompaniesAndPersons,
} from 'utils/report/professionalReport';

export const basicInfo = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['basicInformation', 'manager', 'managersHistory', 'previousCompanyNames'])
);

export const companyBasicInfo = createSelector(
  state => state.companyCreditLimit?.basicData,
  basicData => _pick(basicData['basicInformation'])
);

export const basicContact = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['companyContact', 'companyAddressesHistory', 'rekvizitaiContacts'])
);

export const basicBoard = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['boardMember', 'boardMemberHistory'])
);

export const basicShares = createSelector(
  state => state.professionalReport?.basicData,
  ({ shares, shareholders, shareholdersHistory, jadisInformation, jadisInformationHistory }) => ({
    shares,
    jadisInformation,
    jadisInformationHistory,
    shareholders: unifyCompaniesAndPersons(shareholders),
    shareholdersHistory: unifyCompaniesAndPersons(shareholdersHistory),
  })
);

export const basicEmployeesAndWages = createSelector(
  state => state.professionalReport?.basicData,
  ({ employeesNumber, wages, companyEmployeesSectorsNumbers, companyAndSectorsAverageWages }) => ({
    employeesNumber: employeesNumber.filter(({ date }) => isAfter(new Date(date), subYears(new Date(), 10))),
    employeesTable: createEmployeesTable(employeesNumber),
    wages: wages.map(w => {
      const averageLabel = Math.round(w.average);
      return { ...w, averageLabel };
    }),
    companyEmployeesSectorsNumbers,
    companyAndSectorsAverageWages,
  })
);

export const basicVehicles = createSelector(
  state => state.professionalReport?.basicData,
  ({ vehicles, vehiclesDetails, vehiclesFleetValues = {} }) => {
    const { ownersAndManagers = [], managers = [], owners = [] } = vehiclesFleetValues;

    const mergeVehiclesData = (vehicles, fleetValues) => {
      const mergedData = [];
      const vehiclesMap = new Map(vehicles?.map(vehicle => [vehicle?.date, vehicle]));
      const fleetValuesMap = new Map();

      for (const fleetValue of fleetValues || []) {
        const date = fleetValue.date;
        fleetValuesMap.has(date)
          ? (fleetValuesMap.get(date).value += fleetValue.value)
          : fleetValuesMap.set(date, { ...fleetValue });
      }

      for (const entry of vehicles || []) {
        const date = entry.date;

        if (vehiclesMap.has(date) && fleetValuesMap.has(date)) {
          const fleetValue = fleetValuesMap.get(date);

          const formattedAmount = fleetValue.value.toLocaleString('lt-LT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          const mergedEntry = {
            ...entry,
            categories: [
              ...entry.categories,
              {
                categoryName: 'amount',
                count: formattedAmount,
                date: entry.date,
              },
            ],
          };

          mergedData.push(mergedEntry);
        }
      }

      return mergedData;
    };

    const mergedDataMap = {
      managers: mergeVehiclesData(vehicles?.managers, vehiclesFleetValues?.managers),
      owners: mergeVehiclesData(vehicles?.owners, vehiclesFleetValues?.owners),
      ownersAndManagers: mergeVehiclesData(vehicles?.ownersAndManagers, vehiclesFleetValues?.ownersAndManagers),
    };

    return {
      vehicles: Object.keys(mergedDataMap)
        .map(category => (mergedDataMap[category].length > 0 ? createVehiclesTable(mergedDataMap, category) : null))
        .filter(hasValue => hasValue),
      vehiclesDetails,
      vehiclesFleetValues,
      vehiclesFleetOwnersAndManagers: createVehicleFleetTable(ownersAndManagers),
      vehiclesFleetManagers: createVehicleFleetTable(managers),
      vehiclesFleetOwners: createVehicleFleetTable(owners),
    };
  }
);

export const basicReports = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['statisticsBySector'])
);

export const basicPressReleases = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['pressReleases', 'pressReleasesVersloZinios'])
);

export const basicTrademarks = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['trademarks'])
);

export const basicExport = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['export'])
);

export const authorizedCapitalSelector = createSelector(
  state => state.professionalReport?.basicData,
  basicData => _pick(basicData, ['authorizedCapitalHistory', 'authorizedCapital'])
);

export const bankruptcyInfoSelector = createSelector(
  state => state.professionalReport?.basicData,
  basicData => {
    const arr = basicData?.bankruptcyInformation;

    return { bankruptcyInformation: !!arr.length ? arr : null };
  }
);

export const restructurizationsSelector = createSelector(
  state => state.professionalReport?.basicData,
  basicData => {
    const arr = basicData?.restructurizations;

    return { restructurizations: !!arr.length ? arr : null };
  }
);
