import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated } from '../../store/entities/user';
import { setCustomPdfValues } from '../../store/entities/professionalReport';
import { getFeatures } from 'store/entities/user/thunks';
import useRedirect from 'hooks/navigation/useRedirect';

const useAuthCheck = ({ isPdfReport, location }) => {
  const dispatch = useDispatch();
  const { hasSession = false, isAuthenticated } = useSelector(state => state.user);
  const redirect = useRedirect();

  const isCompanyResultPage = pathname => {
    const companyResultRegex = /^\/search\/company\/\d+\/result$/;
    return companyResultRegex.test(pathname);
  };

  const checkAuth = () => {
    const customPdfValues = localStorage.getItem('customPdfValues');
    if (customPdfValues) {
      dispatch(setCustomPdfValues(JSON.parse(atob(customPdfValues))));
    }
    const token = localStorage.getItem('token');
    const tokenExpired = localStorage.getItem('tokenExp');
    const isTokenExpired = tokenExpired && tokenExpired < Date.now() / 1000;

    if (isTokenExpired) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExp');
    }

    const hasAuth = !!(token && hasSession && !isTokenExpired);
    if (hasAuth !== isAuthenticated) {
      dispatch(setIsAuthenticated(hasAuth));
    }

    if ((!token || isTokenExpired) && !isPdfReport && !isCompanyResultPage(location.pathname)) {
      redirect(`/${location.search}`);
    }
  };

  useEffect(() => {
    window.addEventListener('storage', checkAuth);

    return () => {
      window.removeEventListener('storage', checkAuth);
    };
  }, []);

  useEffect(() => {
    const isExcludedRoute =
      (location?.pathname?.includes('/search/company/') && location?.pathname?.includes('/professional-report')) ||
      (location?.pathname?.includes('/search/company/') && location?.pathname?.includes('/start-report')) ||
      (location?.pathname?.includes('/search/company/') && location?.pathname?.includes('/financial-statements')) ||
      (location?.pathname?.includes('/search/company/') && location?.pathname?.includes('/company-credit-limit')) ||
      (location?.pathname?.includes('/search/company/') && location?.pathname?.includes('/prescore-report')) ||
      (location?.pathname?.includes('/search/company/') && location?.pathname?.includes('/main-information')) ||
      (location?.pathname?.includes('/search/individual/') && location?.pathname?.includes('/professional-report')) ||
      location?.pathname?.includes('/monitorings/credit-monitoring/') ||
      location?.pathname?.includes('/spiderweb');
    if (!isExcludedRoute || isPdfReport) {
      checkAuth();
      dispatch(getFeatures());
    }
  }, [hasSession]);
};

export default useAuthCheck;
