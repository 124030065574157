import { fetchFromApi, optionsForGetRequest, optionsForPostRequest, optionsForPutRequest } from './';

const addCompanyDebtor = async params =>
  fetchFromApi('/company/administration/debts/add', optionsForPostRequest(params));
const addPersonDebtor = async params => fetchFromApi('/person/administration/debts', optionsForPostRequest(params));

const getDebtId = async () => fetchFromApi('/company/administration/debts/generated-debtId');

const getDebtorInformation = async ({ companyCode }) =>
  fetchFromApi(`/company/administration/debts/search-debtor?companyCode=${companyCode}`, optionsForGetRequest());

const getCompanyOrPersonCoveredDebtsList = async params =>
  fetchFromApi(`/${params.companyOrPerson}/administration/debts/covered`, optionsForPostRequest(params));

const getCompanyOrPersonUncoveredDebtsList = async params =>
  fetchFromApi(`/${params.companyOrPerson}/administration/debts/uncovered`, optionsForPostRequest(params));

const getDebtTypes = async ({ companyOrPerson }) =>
  fetchFromApi(`/${companyOrPerson}/administration/debts/types`, optionsForGetRequest());

const updateDebtPayment = async ({ paymentDate, caseNr, amount, id, companyOrPerson }) =>
  fetchFromApi(
    `/${companyOrPerson}/administration/debts/payment`,
    optionsForPutRequest({
      paymentDate,
      caseNr,
      amount,
      id,
    })
  );

const updateDebtAmount = async ({ amount, id, companyOrPerson }) =>
  fetchFromApi(
    `/${companyOrPerson}/administration/debts`,
    optionsForPutRequest({
      amount,
      id,
    })
  );

const getDebtsExcel = async ({ type }) =>
  fetchFromApi(`/${type}/administration/debts/xlsx/preview`, optionsForGetRequest());

export default {
  addCompanyDebtor,
  addPersonDebtor,
  getDebtId,
  getDebtorInformation,
  getCompanyOrPersonCoveredDebtsList,
  getCompanyOrPersonUncoveredDebtsList,
  getDebtTypes,
  updateDebtPayment,
  updateDebtAmount,
  getDebtsExcel,
};
