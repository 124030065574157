import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { negativeDataPaymentHistory } from 'store/entities/professionalReport/selectors/negativeDataSelectors';

import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import PaymentHistoryCard from 'components/reusable/report/cards/PaymentHistoryCard';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';

const PaymentHistory = () => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const { paidDebts, paidDebtsDetails } = useSelector(negativeDataPaymentHistory);

  const detailedInfoColumns = [
    {
      title: t('professionalReport.negativeData.paymentHistory.detailedInformation.claimDate'),
      dataIndex: 'claimDate',
      key: 'claimDate',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.claimDate);
        const dateB = new Date(b.claimDate);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : null,
    },
    {
      title: t('professionalReport.negativeData.paymentHistory.detailedInformation.paymentDate'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.paymentDate);
        const dateB = new Date(b.paymentDate);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : null,
    },
    {
      title: t('professionalReport.negativeData.paymentHistory.detailedInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: amount => {
        return formatNumber(amount);
      },
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.paymentHistory.detailedInformation.creditor'),
      dataIndex: 'creditor',
      key: 'creditor',
      sorter: (a, b) => a.creditor.localeCompare(b.creditor),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.paymentHistory.detailedInformation.claimType'),
      dataIndex: 'claimType',
      key: 'claimType',
      sorter: (a, b) => a.claimType.localeCompare(b.claimType),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.paymentHistory.detailedInformation.claimId'),
      dataIndex: 'claimId',
      key: 'claimId',
      sorter: (a, b) => a.claimId.localeCompare(b.claimId),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <div id="openDebts">
      <PaymentHistoryCard paymentHistoryData={paidDebts}>
        {paidDebtsDetails?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('professionalReport.negativeData.paymentHistory.detailedInformation.title')}
            data={paidDebtsDetails}
            columns={detailedInfoColumns}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
          />
        ) : null}
      </PaymentHistoryCard>
    </div>
  );
};

export default PaymentHistory;
