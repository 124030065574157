import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Space } from 'antd';
import useNumberFormatter from 'hooks/format/useNumberFormatter';
import { ReactComponent as Money } from 'assets/img/icons/money.svg';
import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import TableColumnDate from 'components/reusable/TableColumnDate';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { BlockHeader } from '../../../components/reusable/BlockHeader';
import { ReactComponent as History } from '../../../assets/img/icons/history.svg';

const OtherDebts = ({ otherDebts, otherDebtsDetails }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatCurrency = useNumberFormatter();
  const isOtherDebtsDetailsNotPresent = !otherDebtsDetails || otherDebtsDetails.length === 0;

  const otherDebtsDetailsColumns = useMemo(() => {
    return [
      {
        title: t('openDebts.claims.detailedInformation.claimDate'),
        dataIndex: 'claimDate',
        render: TableColumnDate,
        width: isUiHidden ? 90 : 120,
      },
      {
        title: t('openDebts.claims.detailedInformation.amount'),
        dataIndex: 'amount',
      },
      {
        title: t('openDebts.claims.detailedInformation.creditor'),
        dataIndex: ['creditorName', 'creditorSource'],
        render: (claimsData, { creditor, creditorSource }) => {
          if (creditor === creditorSource) {
            return creditor;
          } else {
            return `${creditor} / ${creditorSource}`;
          }
        },
      },
      {
        title: t('openDebts.claims.detailedInformation.claimType'),
        dataIndex: 'claimType',
      },
      {
        title: t('openDebts.claims.detailedInformation.claimId'),
        dataIndex: 'claimId',
      },
    ];
  }, [t, TableColumnDate]);

  const otherDebtsDetailsData = useMemo(() => {
    if (isOtherDebtsDetailsNotPresent) {
      return [];
    }

    return otherDebtsDetails.map(debt => {
      const { claimDate, amount, creditor, creditorSource, claimType, claimId } = debt;

      return {
        claimDate,
        // TODO: info likely received from list of individual entities
        amount: formatCurrency(amount),
        creditor,
        creditorSource,
        claimType,
        claimId,
      };
    });
  }, [isOtherDebtsDetailsNotPresent, otherDebtsDetails, formatCurrency]);

  if (!otherDebts) {
    return <></>;
  }

  const { total } = otherDebts;

  return (
    <CompanyDataCard icon={<Money className="icon--gray" />} title={t('individualReport.otherDebts.title')}>
      <CompanyDataRow type={t('individualReport.otherDebts.payments')} value={total} />
      {otherDebtsDetailsData?.length ? (
        <Row>
          <CollapsibleTable
            className="space-top-md"
            title={t('openDebts.claims.detailedInformation')}
            columns={otherDebtsDetailsColumns}
            data={otherDebtsDetailsData}
            scroll={false}
          />
        </Row>
      ) : null}
    </CompanyDataCard>
  );
};

export default OtherDebts;
