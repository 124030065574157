import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import useRedirect from 'hooks/navigation/useRedirect';
import { signIn, getSession } from 'store/entities/user/thunks';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Button, Col, Form, Input, Row, Typography } from 'antd';

const { Title } = Typography;

const RegisteredUsersForm = ({ setLoginOption }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const redirect = useRedirect();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { loginError } = useSelector(state => state.user);

  const handleFinishForm = async values => {
    const token = await executeRecaptcha('login');
    const signInResult = await dispatch(
      signIn({
        ...values,
        languageCode: lang,
        token: token || '',
      })
    );

    if (signIn.fulfilled.match(signInResult)) {
      const sessionResult = await dispatch(getSession());

      if (getSession.fulfilled.match(sessionResult)) {
        // Ensure the session state is fully updated before redirecting
        setTimeout(() => {
          redirect('/search/company');
        }, 100); // Small delay to ensure state update propagation
      }
    }
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Form
        name="registeredUsersLogin"
        initialValues={{}}
        onFinish={handleFinishForm}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        requiredMark={false}
      >
        <div className="login-subtitle">{t('login.subtitle')}</div>
        <Form.Item
          label={t('login.username')}
          name="userName"
          rules={[{ required: true, message: 'Please input your username!' }]}
          validateStatus={loginError ? 'error' : ''}
          help={loginError && t('common.wrongUsernameOrPassword')}
        >
          <Input className="login-block-input" placeholder={t('login.username.placeholder')} />
        </Form.Item>

        <Form.Item
          label={t('login.password')}
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
          validateStatus={loginError ? 'error' : ''}
          help={loginError && t('common.wrongUsernameOrPassword')}
        >
          <Input.Password className="login-block-input" placeholder={t('login.password.placeholder')} />
        </Form.Item>

        <Row gutter={[0, 16]} justify="space-between" className="login-button-block">
          <Col xs={24} md={11}>
            <Button className="login-btn" size="default" onClick={() => setLoginOption(null)}>
              {t('global.back')}
            </Button>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item>
              <Button className="login-btn btn-xl__secondary-green" htmlType="submit">
                {t('login.login')}
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ span: 24 }}>
          <a className="login-form-forgot" onClick={() => setLoginOption('forgotPassword')}>
            {t('login.forgotPassword')}
          </a>
        </Form.Item>
      </Form>
    </>
  );
};

export default RegisteredUsersForm;
