import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { useDispatch, useSelector } from 'react-redux';
import { buyReportData } from 'store/entities/companySearchResult/selectors/buyReportSelector';
import { completeStep, setBuyReportStep } from 'store/entities/companySearchResult';

import { Col, Row } from 'antd';
import OrderActions from 'views/CompanySearchResult/components/BuyReport/OrderActions';
import { ReactComponent as ShoppingCart } from 'assets/img/icons/shoppingCart.svg';
import { ReactComponent as Document } from 'assets/img/icons/document.svg';
import { Divider } from 'antd/es';
import { basicData } from '../../../../store/entities/companySearchResult/selectors/unregisteredReportSelector';
import SingleResult from 'components/reusable/SingleResult';

const excludedPropsFromRender = ['token', 'createdDate'];

const OrderConfirmation = () => {
  const [infoTableData, setInfoTableData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    step,
    paymentType,
    buyerType,
    companyBuyerInformation,
    personBuyerInformation,
    selectedReportType,
    encoded,
    uri,
  } = useSelector(buyReportData);
  const { companyName, companyCode, status, address } = useSelector(basicData);
  const redirectForm = useRef(null);
  const isCompany = buyerType === 'company';

  useEffect(() => {
    if (paymentType) {
      dispatch(completeStep(3));
    }
  }, [paymentType]);

  useEffect(() => {
    const buyerInformation = isCompany ? companyBuyerInformation : personBuyerInformation;
    const tableData = Object.keys(buyerInformation)
      .filter(key => typeof buyerInformation[key] === 'string')
      .filter(key => !excludedPropsFromRender.includes(key))
      .map(key => ({
        key,
        value: buyerInformation[key],
        form: true,
      }));

    setInfoTableData(tableData);
  }, [companyBuyerInformation, personBuyerInformation]);

  const handleSubmit = useCallback(() => {
    redirectForm.current.submit();

    dispatch(completeStep(3));
  }, [redirectForm.current]);

  const RecordRow = ({ title, value }) => {
    return (
      <Row gutter={[24, 0]}>
        <Col xs={12} sm={12} md={12} lg={10} xl={9} xxl={8}>
          {title}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={14}
          xl={15}
          xxl={16}
          className="fw-500"
          style={{ overflowWrap: 'break-word', margin: 'auto' }}
        >
          {value}
        </Col>
      </Row>
    );
  };

  const BuyerInformation = () => {
    return isCompany ? (
      <>
        {infoTableData.map(data => (
          <RecordRow
            title={t(`companyResult.buyReport.companyInformationForm.${data.key}`)}
            value={data.value || '-'}
          />
        ))}
      </>
    ) : (
      <>
        {infoTableData.map(data => (
          <RecordRow
            title={t(`companyResult.buyReport.individualInformationForm.${data.key}`)}
            value={data.value || '-'}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <SingleResult carded result={{ code: companyCode, name: companyName, status, address }}>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <strong className="color-secondary flex">
              <ShoppingCart />
              {t('companyResult.buyReport.confirmation.yourCart')}
            </strong>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="space-vertical-sm">
          <Col xs={24} className="table-headless table-padded">
            <RecordRow
              title={i18next.t(`companyResult.buyReport.reportTable.${selectedReportType}`)}
              value={
                selectedReportType === 'financialStatement'
                  ? t('companyResult.reportPrice.financialStatement')
                  : t('companyResult.reportPrice.nonFinancialStatement')
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <strong className="color-secondary flex">
              <Document />
              {t('companyResult.buyReport.confirmation.customerInformation')}
            </strong>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="space-vertical-sm">
          <Col xs={24} className="table-headless table-padded">
            {infoTableData && <BuyerInformation />}
          </Col>
        </Row>
        <form
          ref={e => (redirectForm.current = e)}
          acceptCharset="UTF-8"
          name="redirectionForm"
          method="post"
          target="_self"
          rel="external nofollow noreferrer noopener"
          action={uri}
          hidden
        >
          <input type="hidden" name="encoded" value={encoded} tabIndex={-1} />
        </form>
      </SingleResult>
      <OrderActions
        handleContinueButton={handleSubmit}
        handleBackButton={() => dispatch(setBuyReportStep(step - 1))}
        backBtnId="company-result-back-third"
        submitBtnId="company-result-pay"
      />
    </>
  );
};

export default OrderConfirmation;
