import React from 'react';
import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'hooks/format/useFormatDate';
import { Col, Row } from 'antd';

const FinancialStatementCard = ({ financialStatement, children }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const year = financialStatement?.financialYear || financialStatement?.financialStatementYear || '-';
  const months = financialStatement?.financialMonth || financialStatement?.monthsIncluded || '-';
  const yearFrom = formatDate(financialStatement?.financialYearBegin) || formatDate(financialStatement?.periodFrom);
  const yearTo = formatDate(financialStatement?.financialYearEnd) || formatDate(financialStatement?.periodTo);

  return (
    <CompanyDataCard
      icon={<InfoCircleOutlined />}
      title={
        <Row justify="space-between">
          <Col>{t('professionalReport.financialData.periodOfStatement.title')}</Col>
          {financialStatement?.categoryType && financialStatement?.categoryType !== 'Undefined' && (
            <Col>{t(`financialStatements.categoryType.${financialStatement.categoryType}`)}</Col>
          )}
        </Row>
      }
    >
      <CompanyDataRow type={t('professionalReport.financialData.financialStatementYear')} value={year} />
      <CompanyDataRow type={t('professionalReport.financialData.monthsIncluded')} value={months} />
      <CompanyDataRow
        type={t('professionalReport.financialData.period')}
        value={yearFrom && yearTo ? `${yearFrom} - ${yearTo}` : '-'}
      />
      <CompanyDataRow
        type={t('professionalReport.financialData.submissionDate')}
        value={formatDate(financialStatement?.submissionDate) || '-'}
      />
      <CompanyDataRow type={t('professionalReport.financialData.source')} value={financialStatement?.source || '-'} />
      {financialStatement?.isDeleted && (
        <CompanyDataRow
          type={t('professionalReport.financialData.state')}
          value={t('professionalReport.financialData.state.isDeleted')}
        />
      )}
      {financialStatement?.reportType && financialStatement.reportType !== 'Undefined' && (
        <CompanyDataRow
          type={t('professionalReport.financialData.type')}
          value={`${t(`financialStatements.reportType.${financialStatement.reportType}`)} ${t(
            'professionalReport.financialData.type.financialReports'
          )}`}
        />
      )}

      {children}
    </CompanyDataCard>
  );
};

export default FinancialStatementCard;
