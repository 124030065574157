import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import UnauthenticatedWrapper from 'views/Search/UnauthenticatedSearch/UnauthenticatedWrapper';

const ReportPurchaseStatus = () => {
  const { status } = useParams();
  const { t } = useTranslation();

  const hasError = Number.parseInt(status, 10) !== 1;
  const statusText = hasError ? 'error' : 'success';

  const title = `reportPurchaseStatus.${statusText}.title`;
  const subTitle = `reportPurchaseStatus.${statusText}.subTitle`;
  const btnText = `reportPurchaseStatus.${statusText}.btnText`;

  const btnClassName = cx('report-purchase-status-footer__btn', {
    'btn-success': !hasError,
    'btn-secondary': hasError,
  });
  const iconClassName = cx('report-purchase-status-body__icon', {
    'report-purchase-status-body__icon--success': !hasError,
  });

  const iconComponent = hasError ? (
    <CloseCircleFilled className={iconClassName} />
  ) : (
    <CheckCircleFilled className={iconClassName} />
  );

  return (
    <UnauthenticatedWrapper>
      <div
        className={`report-purchase-status ${
          hasError ? 'report-purchase-status-error' : 'report-purchase-status-success'
        }`}
      >
        <div className="report-purchase-status-header">
          <h4 className="report-purchase-status-header__title">{t(title)}</h4>
          <h5 className="report-purchase-status-header__subtitle">{t(subTitle)}</h5>
        </div>
        <div className="report-purchase-status-body">{iconComponent}</div>
        <div className="report-purchase-status-footer">
          <Button type="default" href="/search/company" className={btnClassName}>
            {t(btnText)}
          </Button>
        </div>
      </div>
    </UnauthenticatedWrapper>
  );
};

export default ReportPurchaseStatus;
