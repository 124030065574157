import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { negativeDataOpenDebts } from 'store/entities/professionalReport/selectors/negativeDataSelectors';

import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import PossibilityIndicator from 'components/reusable/PossibilityIndicator';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import OpenDebtsCard from 'components/reusable/report/cards/OpenDebtsCard';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';
import { useIsMdOrLess } from '../../../hooks/dom/useMedia';

const OpenDebts = () => {
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const screenIsMdOrLess = useIsMdOrLess();

  const { activeDebts, activeDebtsDetails } = useSelector(negativeDataOpenDebts);

  const handleLinkClick = (e, code) => {
    e.preventDefault();
    const newPath = `/search/company/${code}`;
    window.open(newPath, '_blank');
  };

  const renderCreditorLink = (text, record) => {
    return (
      <a
        href={`/search/company/${record.creditorCompanyCode}`}
        onClick={event => handleLinkClick(event, record.creditorCompanyCode)}
      >
        {text}
      </a>
    );
  };

  const detailedInfoColumns = [
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.date'),
      dataIndex: 'claimDate',
      key: 'claimDate',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.claimDate) - new Date(a.claimDate),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 90 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: amount => {
        return formatNumber(amount);
      },
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.creditor'),
      dataIndex: 'creditor',
      key: 'creditor',
      render: renderCreditorLink,
      sorter: (a, b) => a.creditor.localeCompare(b.creditor),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.claimType'),
      dataIndex: 'claimType',
      key: 'claimType',
      sorter: (a, b) => a.claimType.localeCompare(b.claimType),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.negativeData.openDebts.detailedInformation.claimId'),
      dataIndex: 'claimId',
      key: 'claimId',
      sorter: (a, b) => a.claimId.localeCompare(b.claimId),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
  ];

  return (
    <div id="openDebts">
      <OpenDebtsCard openDebtsData={activeDebts}>
        {activeDebtsDetails?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('professionalReport.negativeData.openDebts.detailedInformation.title')}
            data={activeDebtsDetails}
            columns={detailedInfoColumns}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
            showSorterTooltip={false}
          />
        ) : null}
      </OpenDebtsCard>
    </div>
  );
};

export default OpenDebts;
