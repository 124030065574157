import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { basicTrademarks } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import TableColumnDate from 'components/reusable/TableColumnDate';

import { Col, Row, Table, Card } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as TrademarkIcon } from 'assets/img/icons/trademark.svg';
import { BlockHeader } from '../../../components/reusable/BlockHeader';
import { CompanyDataRow } from '../../../components/reusable/CompanyDataCard';

const Trademarks = ({ briefPdf }) => {
  const [customReport, setCustomReport] = useState(null);
  const { t } = useTranslation();
  const { trademarks } = useSelector(basicTrademarks);
  const isUiHidden = useIsUiHidden();

  const { customPdfValues } = useSelector(state => state.professionalReport);

  useEffect(() => {
    if (customPdfValues && 'CompanyTrademarks' in customPdfValues) {
      setCustomReport(customPdfValues['CompanyTrademarks']);
    }
  }, [customPdfValues]);

  const trademarksColumns = [
    {
      title: t('professionalReport.basicData.trademarks.trademarkThumbnail'),
      dataIndex: 'trademarkThumbnail',
      key: 'trademarkThumbnail',
      render: trademarkThumbnail =>
        trademarkThumbnail ? <img src={trademarkThumbnail} alt="Trademark Thumbnail" style={{ padding: 0 }} /> : '-',
    },
    {
      title: t('professionalReport.basicData.trademarks.trademark'),
      dataIndex: 'title',
      key: 'title',
      render: (title, record) =>
        record?.trademarkImage ? (
          <a href={record.trademarkImage} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        ) : (
          title
        ),
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.trademarks.legalStatus'),
      dataIndex: 'legalStatus',
      key: 'legalStatus',
      sorter: (a, b) => a.legalStatus.localeCompare(b.legalStatus),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.trademarks.applicationDate'),
      dataIndex: 'applicationDate',
      render: TableColumnDate,
      key: 'applicationDate',
      sorter: (a, b) => new Date(b.applicationDate) - new Date(a.applicationDate),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
      defaultSortOrder: 'ascend',
    },
    {
      title: t('professionalReport.basicData.trademarks.regDate'),
      dataIndex: 'registrationDate',
      render: TableColumnDate,
      key: 'registrationDate',
      sorter: (a, b) => new Date(b.registrationDate) - new Date(a.registrationDate),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.basicData.trademarks.regNo'),
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      sorter: (a, b) => a.registrationNumber.localeCompare(b.registrationNumber),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.trademarks.expires'),
      dataIndex: 'expireDate',
      render: TableColumnDate,
      key: ' expireDate',
      sorter: (a, b) => new Date(b.expireDate) - new Date(a.expireDate),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
  ];

  return !isUiHidden || trademarks?.length ? (
    <div className="no-break">
      <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
        <Row id="trademarks" justify="start">
          <Col>
            <BlockHeader
              icon={<Icon component={TrademarkIcon} className="icon--gray" />}
              title={t('professionalReport.basicData.trademarks.title')}
            />
          </Col>
        </Row>
        {!briefPdf && (
          <CompanyDataRow type={t('professionalReport.basicData.trademarks.count')} value={trademarks.length} />
        )}
        {customPdfValues && !customReport ? (
          <Row>
            <Card className="card-transparent space-vertical-sm">
              {t('professionalReport.basicData.trademarks.customDisclaimer')}
            </Card>
          </Row>
        ) : (
          <Row>
            <Col xs={24} md={24}>
              <Table
                bordered
                dataSource={briefPdf ? trademarks.slice(0, 10) : trademarks}
                columns={trademarksColumns}
                scroll={isUiHidden ? false : { x: 'max-content' }}
                pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
                showSorterTooltip={false}
              />
            </Col>
          </Row>
        )}
        {briefPdf && (
          <Row>
            <Card className="card-transparent space-vertical-sm">
              {t('professionalReport.basicData.trademarks.brief.disclaimer')}
            </Card>
          </Row>
        )}
      </Card>
    </div>
  ) : null;
};

export default Trademarks;
