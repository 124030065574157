import { combineReducers } from 'redux';
import ui from 'store/entities/ui';
import user from 'store/entities/user';
import footer from 'store/entities/footer';
import company from 'store/entities/company';
import person from 'store/entities/person';
import professionalReport from 'store/entities/professionalReport';
import companySearchResult from 'store/entities/companySearchResult';
import otherProducts from 'store/entities/otherProducts';
import companyCreditLimitReport from 'store/entities/companyCreditLimitReport';
import startReport from 'store/entities/startReport';
import prescoreReport from 'store/entities/prescoreReport';
import mainInformation from 'store/entities/mainInformation';
import companyFinancialReport from 'store/entities/companyFinancialReport';
import spiderWeb from 'store/entities/spiderWeb';
import administration from 'store/entities/administration';

export default combineReducers({
  ui,
  user,
  footer,
  company,
  person,
  professionalReport,
  companySearchResult,
  otherProducts,
  companyCreditLimitReport,
  startReport,
  prescoreReport,
  mainInformation,
  companyFinancialReport,
  spiderWeb,
  administration,
});
