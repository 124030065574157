import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Popover } from 'antd';

const ReportRow = ({ reportName, onBtnClick = () => {}, btnText = 'global.open', popoverText = false }) => {
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <>
      {popoverText ? (
        <Popover trigger="hover" content={<div style={{ maxWidth: 500 }}>{popoverText}</div>}>
          <Row
            gutter={[16, 16]}
            className={`${hovered ? 'activeRow' : ''}`}
            justify="space-between"
            align="middle"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ padding: 10 }}
            onClick={onBtnClick}
          >
            <Col xs={24} lg={12} xl={17}>
              <strong>{reportName}</strong>
            </Col>
            <Col xs={24} lg={12} xl={7}>
              <Button size="small">{t(btnText)}</Button>
            </Col>
          </Row>
        </Popover>
      ) : (
        <Row
          gutter={[16, 16]}
          className={`${hovered ? 'activeRow' : ''}`}
          justify="space-between"
          align="middle"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ padding: 10 }}
          onClick={onBtnClick}
        >
          <Col xs={24} lg={12} xl={17}>
            <strong>{reportName}</strong>
          </Col>
          <Col xs={24} lg={12} xl={7}>
            <Button size="small">{t(btnText)}</Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ReportRow;
