import CompanyDataCard from '../../../components/reusable/CompanyDataCard';
import { Button, Col, Radio, Space } from 'antd';
import useFormatDate, { DATE_FORMAT_LONG } from '../../../hooks/format/useFormatDate';
import { getChildOrderIdFinancialStatement, getFinancialStatements } from '../../../store/entities/startReport/thunks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DetailedFinancialStatements } from '../../../components/reusable/DetailedFinancialStatements';

export const OptionalStatements = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const [selectedOptionalStatement, setSelectedOptionalStatement] = useState(null);
  const { selectableFinancialStatements, financialStatementChildOrderId, financialData } = useSelector(
    state => state.startReport
  );

  const findStatementById = id => {
    const statement = selectableFinancialStatements.find(stmt => stmt?.financialStatementId === id);

    if (statement) {
      return {
        year: statement?.financialYear,
        financialStatementId: statement?.financialStatementId,
      };
    }

    return null;
  };

  useEffect(() => {
    if (financialStatementChildOrderId) {
      // Trigger the second request when financialStatementChildOrderId is available
      dispatch(getFinancialStatements(financialStatementChildOrderId));
    }
  }, [financialStatementChildOrderId]);

  // Filter out statements that are already displayed
  const filteredOptionalFinancialStatements = selectableFinancialStatements
    ? selectableFinancialStatements.filter(statement => {
        const targetFinancialStatementId = statement.financialStatementId;

        return financialData?.activePurchasedFinancialStatements
          ? financialData.activePurchasedFinancialStatements.every(
              purchasedStatement => purchasedStatement.financialStatementId !== targetFinancialStatementId
            )
          : true;
      })
    : null;

  return (
    <>
      {selectableFinancialStatements?.length && filteredOptionalFinancialStatements?.length ? (
        <CompanyDataCard title={t('startReport.financialStatements.optional.title')}>
          <Col>
            <Radio.Group
              onChange={event => setSelectedOptionalStatement(event.target.value)}
              value={selectedOptionalStatement}
            >
              <Space direction="vertical">
                {filteredOptionalFinancialStatements.map(statement => {
                  return (
                    <Radio key={statement.financialStatementId} value={statement?.financialStatementId}>
                      {t('startReport.financialStatements.optional.radio', {
                        year: statement?.financialYear,
                        dateFrom: formatDate(statement?.financialYearBegin, DATE_FORMAT_LONG),
                        dateTo: formatDate(statement?.financialYearEnd, DATE_FORMAT_LONG),
                        source: statement?.source,
                      })}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Col>
          <Button
            className="btn-secondary buy-report"
            onClick={() =>
              dispatch(
                getChildOrderIdFinancialStatement({
                  companyCode: companyId,
                  optionalObj: findStatementById(selectedOptionalStatement),
                })
              )
            }
            disabled={!selectedOptionalStatement}
          >
            {t('startReport.financialStatements.optional.buy.button')}
          </Button>
        </CompanyDataCard>
      ) : null}
      {financialData?.activePurchasedFinancialStatements?.length > 0 &&
        financialData.activePurchasedFinancialStatements.map(financialStatement => {
          const targetFinancialStatementId = financialStatement.financialStatementId;
          const targetFinancialStatement = selectableFinancialStatements.find(
            statement => statement.financialStatementId === targetFinancialStatementId
          );
          return (
            <DetailedFinancialStatements financialStatement={financialStatement} statement={targetFinancialStatement} />
          );
        })}
    </>
  );
};
