import React, { useState } from 'react';
import { Table } from 'antd';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { useTranslation } from 'react-i18next';

const CustomRequestTable = ({
  data,
  total,
  handleOnFilter,
  columns,
  searchParams,
  pageSizeOptions = [10, 20, 50, 100],
  defaultPageSize = 10,
  current = 1,
  handleTableChange = null,
}) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(current);

  const handlePageChange = ({ current, pageSize: size }) => {
    if (current === page) {
      setPageSize(size);
      setPage(1);
      handleOnFilter({ ...searchParams, page: 1, pageSize: size });
    } else {
      setPageSize(size);
      setPage(current);
      handleOnFilter({ ...searchParams, page: current, pageSize: size });
    }
  };

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={data}
      columns={columns}
      scroll={isUiHidden ? false : { x: 'max-content' }}
      pagination={
        isUiHidden
          ? false
          : {
              defaultPageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions,
              total: total,
              current: current ? current : page,
              showTotal: total => t('global.table.total', { total }),
            }
      }
      onChange={handleTableChange ? handleTableChange : handlePageChange}
      showSorterTooltip={false}
    />
  );
};

export default CustomRequestTable;
