import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useCurrentLanguage from 'hooks/user/useCurrentLanguage';
import { getNegativeData } from 'store/entities/professionalReport/thunks';
import { negativeDataUnreliableCompanies } from 'store/entities/professionalReport/selectors/negativeDataSelectors';

import { Row, Col, Skeleton } from 'antd';

import OpenDebts from './OpenDebts';
import PaymentHistory from './PaymentHistory';
import DebtCollections from './DebtCollections';
import PropertyArrests from './PropertyArrests';
import LegalProceedings from './LegalProceedings';
import BailiffsInformation from './BailiffsInformation';
import UnreliableCompanies from 'components/reusable/report/cards/UnreliableCompanies';
import SodraDebtInformation from './SodraDebtInformation';
import OtherInformation from './OtherInformation';
import VMIDebtInformation from './VMIDebInformation';

const NegativeData = ({ briefPdf = false }) => {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const { orderId, negativeData, negativeDataPending, negativeDataError } = useSelector(
    state => state.professionalReport
  );
  const { unreliableCompanies, unreliableCompaniesDetails } = useSelector(negativeDataUnreliableCompanies);

  useEffect(() => {
    if ((orderId && negativeData && lang !== negativeData?.lang) || (!negativeData && !negativeDataError))
      dispatch(getNegativeData(orderId));
  }, [lang]);

  return (
    <Row className="no-break">
      {isUiHidden && (
        <Col span={24}>
          <h2>{t('professionalReport.tab.negativeData')}</h2>
        </Col>
      )}
      <Col span={24}>
        {negativeData && !negativeDataPending ? (
          <>
            <OpenDebts />
            <PaymentHistory />
            <PropertyArrests />
            <LegalProceedings briefPdf={briefPdf} />
            <BailiffsInformation />
            <UnreliableCompanies
              unreliableCompanies={unreliableCompanies}
              unreliableCompaniesDetails={unreliableCompaniesDetails}
            />
            <SodraDebtInformation />
            <VMIDebtInformation />
            <OtherInformation />
          </>
        ) : (
          <Skeleton />
        )}
      </Col>
    </Row>
  );
};

export default NegativeData;
