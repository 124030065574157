import React from 'react';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash.isempty';
import _omit from 'lodash.omit';

import CompanyDataCard, { CompanyDataRowTwoColumn } from 'components/reusable/CompanyDataCard';

import { Button, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const { Link } = Typography;

const CompanyContactInformation = ({ contactData, setUpdateModalOpen, type = 'regular', children }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const renderDataRow = (data, fields, translationPrefix) =>
    !_isEmpty(fields) && (
      <CompanyDataRowTwoColumn
        items={fields.map(fieldName => ({
          type: t(`professionalReport.basicData.${translationPrefix}.${fieldName}`),
          value:
            data[fieldName] && data[fieldName] !== '' ? (
              fieldName === 'uri' ? (
                <Link href={data[fieldName]} target="_blank" rel="noopener noreferrer" underline>
                  {data[fieldName] && data[fieldName] !== '' ? data[fieldName] : '-'}
                </Link>
              ) : (
                data[fieldName]
              )
            ) : (
              '-'
            ),
        }))}
      />
    );

  return type === 'regular' ? (
    <CompanyDataCard
      icon={<MailOutlined />}
      title={<span>{t('professionalReport.basicData.contactInformation.title')}</span>}
    >
      {_isEmpty(contactData) ? (
        t('global.noInformation')
      ) : (
        <>
          {renderDataRow(contactData, ['address', 'email'], 'contactInformation')}
          {renderDataRow(contactData, ['visitAddress', 'uri'], 'contactInformation')}
          {renderDataRow(contactData, ['phone', 'iban'], 'contactInformation')}
          {renderDataRow(contactData, ['mobile', 'bank'], 'contactInformation')}
        </>
      )}
      {children}
    </CompanyDataCard>
  ) : (
    <CompanyDataCard
      icon={<MailOutlined />}
      title={t('professionalReport.basicData.rekvizitaiContactInformation.title')}
    >
      {_isEmpty(_omit(contactData, ['creditinfoId', 'companyCode'])) ? (
        t('global.noInformation')
      ) : (
        <>
          {renderDataRow(contactData, ['address', 'cellPhone'], 'rekvizitaiContactInformation')}
          {renderDataRow(contactData, ['email', 'uri'], 'rekvizitaiContactInformation')}
          {renderDataRow(contactData, ['phone'], 'rekvizitaiContactInformation')}
        </>
      )}
      {children}
    </CompanyDataCard>
  );
};

export default CompanyContactInformation;
