import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useRedirect from 'hooks/navigation/useRedirect';
import { changeLanguage, signOut } from 'store/entities/user/thunks';
import { languages } from 'i18n';

import { Layout, Menu, Row, Col, Typography } from 'antd';
import { UserOutlined, GlobalOutlined, CaretDownFilled, MenuOutlined } from '@ant-design/icons';
import Breadcrumbs from './Breadcrumbs';
import { Link } from 'react-router-dom';

const { Header: LayoutHeader } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const Header = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const redirect = useRedirect();
  const { isAuthenticated, session } = useSelector(state => state.user);

  const handleSignOut = async () => {
    await dispatch(signOut());
    redirect('/');
  };

  const handleChangeLanguage = async languageCode => {
    if (isAuthenticated) {
      const changeResult = await dispatch(changeLanguage(languageCode));

      if (changeLanguage.fulfilled.match(changeResult)) {
        i18n.changeLanguage(languageCode);
      }
    } else {
      i18n.changeLanguage(languageCode);
    }
  };

  return (
    <LayoutHeader style={{ height: 'auto' }}>
      <Row justify="space-between" style={{ flexFlow: 'row' }}>
        <Col span={20} className="breadcrumb-container">
          <Breadcrumbs />
        </Col>
        <Col span={4}>
          <Menu
            mode="horizontal"
            theme="light"
            style={{
              justifyContent: 'end',
              borderBottom: 'none',
            }}
            overflowedIndicator={<MenuOutlined className="only-child" />}
          >
            <SubMenu
              key="lang"
              icon={<GlobalOutlined className="icon--gray" />}
              title={
                <span>
                  {languages.find(({ codeShort }) => i18n.language === codeShort)?.codeLong}

                  <CaretDownFilled />
                </span>
              }
            >
              {languages.map(({ codeShort, codeLong }) => (
                <Menu.Item key={`lang-${codeShort}`} onClick={() => handleChangeLanguage(codeShort)}>
                  {codeLong}
                </Menu.Item>
              ))}
            </SubMenu>
            {isAuthenticated ? (
              <SubMenu
                key="user"
                className="ant-menu-submenu--no-text"
                title={<b>{session?.username?.toUpperCase()}</b>}
                icon={<UserOutlined className="only-child" />}
              >
                <Menu.Item key="setting:1" onClick={() => redirect('/profile')}>
                  {t('header.settings')}
                </Menu.Item>
                <Menu.Item key="setting:2" onClick={handleSignOut}>
                  {t('header.signOut')}
                </Menu.Item>
              </SubMenu>
            ) : (
              <Menu.Item key="login">
                <Link to="/login">
                  <Text className="color-secondary">{t('login.login')}</Text>
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Col>
      </Row>
    </LayoutHeader>
  );
};

export default Header;
