import React from 'react';
import { useSelector } from 'react-redux';

import CompanySearchForm from './CompanySearchForm';
import SearchResultsTable from './SearchResultsTable';
import PrescoreReport from './PrescoreReport';
import Basic from './Basic';

const CompanySearch = () => {
  const { visibleCompanyView } = useSelector(state => state.company);

  return (
    <>
      {(visibleCompanyView === 'table' || visibleCompanyView === 'details') && <CompanySearchForm />}
      {visibleCompanyView === 'table' && <SearchResultsTable />}
      {visibleCompanyView === 'details' && <Basic />}
      {visibleCompanyView === 'prescore-report' && <PrescoreReport />}
    </>
  );
};

export default CompanySearch;
