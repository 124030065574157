import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { negativeDataSodra } from 'store/entities/startReport/selectors/negativeDataSelectors';

import TableColumnDate from 'components/reusable/TableColumnDate';
import ComposedChart from 'components/reusable/graphs/ComposedChart';

import { Col, Row, Table, Typography, Card } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import ReportBlockHeader from '../../../components/reusable/report/ReportBlockHeader';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';

const { Paragraph } = Typography;

const SodraDebtInformation = () => {
  const { t } = useTranslation();
  const { sodraDebtsHistory = [] } = useSelector(negativeDataSodra);
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const sodraChartData = useMemo(
    () => [...sodraDebtsHistory].sort((a, b) => a.dateFrom.localeCompare(b.dateFrom)),
    [sodraDebtsHistory]
  );

  const debtColumns = [
    {
      title: t('professionalReport.negativeData.sodraDebtInformation.dateFrom'),
      dataIndex: 'dateFrom',
      render: TableColumnDate,
      width: isUiHidden ? 90 : null,
    },
    {
      title: t('professionalReport.negativeData.sodraDebtInformation.dateTo'),
      dataIndex: 'dateTo',
      render: TableColumnDate,
      width: isUiHidden ? 90 : null,
    },
    {
      title: t('professionalReport.negativeData.sodraDebtInformation.amount'),
      dataIndex: 'amount',
      render: amount => {
        return formatNumber(amount);
      },
    },
    {
      title: t('professionalReport.negativeData.sodraDebtInformation.rights'),
      dataIndex: 'defferedAmount',
      render: amount => {
        return formatNumber(amount);
      },
    },
  ];

  const filteredChartData = sodraChartData.filter((entry, index, array) => {
    // Check if any other entry has the same year and month as the current entry
    const hasLaterEntrySameYearAndMonth = array.some((otherEntry, otherIndex) => {
      if (index !== otherIndex) {
        const currentDate = new Date(entry.dateFrom);
        const otherDate = new Date(otherEntry.dateFrom);

        return (
          currentDate.getFullYear() === otherDate.getFullYear() &&
          currentDate.getMonth() === otherDate.getMonth() &&
          otherDate > currentDate // Check if the other entry starts later
        );
      }
      return false;
    });

    // Include the entry only if no other entry has the same year and month and starts later
    return !hasLaterEntrySameYearAndMonth;
  });

  return (
    <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
      <div id="sodraDebt" className="space-top-lg">
        <ReportBlockHeader
          icon={<FileTextOutlined className="icon--gray" />}
          title={t('professionalReport.negativeData.sodraDebtInformation.title')}
        />
      </div>
      <Paragraph>{t('professionalReport.negativeData.sodraDebtInformation.description')}</Paragraph>
      {sodraDebtsHistory.length ? (
        <Table
          bordered
          dataSource={sodraDebtsHistory}
          columns={debtColumns}
          scroll={{ x: 'max-content' }}
          pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
        />
      ) : null}
      <Row className="space-vertical-md">
        <Col span={24}>
          <ComposedChart
            id="sodraDebt"
            series={[
              {
                type: 'bar',
                dataKey: 'defferedAmount',
                fill: '#999999',
                key: 'defferedAmount',
              },
              {
                type: 'bar',
                dataKey: 'amount',
                fill: '#C40F11',
                key: 'amount',
              },
            ]}
            data={filteredChartData}
            lazyLoaded
            xAxisDataKey="dateFrom"
            useValueFormatter
            noInformationText="professionalReport.negativeData.sodraDebtInformation.noInformation"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SodraDebtInformation;
