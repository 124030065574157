import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import { setActiveTab } from 'store/entities/professionalReport';
import { basicBoard } from 'store/entities/professionalReport/selectors/basicDataSelectors';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import CompanyBoardMembers from 'components/reusable/report/tables/CompanyBoardMembers';

import { ReactComponent as PeopleIcon } from 'assets/img/icons/people.svg';

import { Col, Row, Typography, Card } from 'antd';
import Icon from '@ant-design/icons';
import { BlockHeader } from '../../../components/reusable/BlockHeader';

const { Text } = Typography;

const Board = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { boardMember, boardMemberHistory } = useSelector(basicBoard);
  const isUiHidden = useIsUiHidden();

  const historicalColumns = [
    {
      title: t('global.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'dateFrom',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('global.dateUntil'),
      dataIndex: 'dateUntil',
      key: 'dateUntil',
      render: TableColumnDate,
      sorter: (a, b) => new Date(b.dateUntil) - new Date(a.dateUntil),
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.basicData.boardMembers.name_surname_position'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName, { position }) => `${fullName}, ${position}`,
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'boardMemberHistorical_actions',
      render: (_, record) => (
        <>
          <p>
            <Link to={`/search/company?manafullNameer=${record?.fullName.split(',')[0]}`} target="_blank">
              <Text type="info">{t('global.managedCompaniesSearch')}</Text>
            </Link>
          </p>
        </>
      ),
    },
  ];

  return (
    <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
      <div className="no-break">
        <Row id="board" justify="start">
          <Col>
            <BlockHeader
              icon={<Icon component={PeopleIcon} className="icon--gray" />}
              title={t('professionalReport.basicData.boardMembers.title')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <VisibilitySensor
              offset={{ bottom: 50 }}
              scrollCheck
              intervalDelay={10}
              onChange={isVisible => isVisible && dispatch(setActiveTab('board'))}
              partialVisibility
              minTopValue={200}
            >
              <CompanyBoardMembers boardMembersData={boardMember} />
            </VisibilitySensor>
          </Col>
        </Row>
        {boardMemberHistory?.length ? (
          <Row>
            <CollapsibleTable
              className="space-top-md"
              title={t('professionalReport.basicData.boardMembers.historicalInformation.title')}
              data={boardMemberHistory}
              columns={isUiHidden ? historicalColumns.slice(0, historicalColumns.length - 1) : historicalColumns}
              bordered
            />
          </Row>
        ) : null}
      </div>
    </Card>
  );
};

export default Board;
