import { useTranslation } from 'react-i18next';

import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import useFormatDate from 'hooks/format/useFormatDate';

import { Col, Row, Card } from 'antd';
import { FileTextOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { negativeDataBailiffsInformation } from '../../../../store/entities/professionalReport/selectors/negativeDataSelectors';
import TableColumnDate from '../../../../components/reusable/TableColumnDate';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';

const BailiffsInformation = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ style: 'decimal' });

  const { bailiffsRemainders, bailiffsRemaindersDetails } = useSelector(negativeDataBailiffsInformation);

  const bailiffsInformationColumns = [
    {
      title: t('professionalReport.negativeData.bailiffsInformation.reminderDate'),
      dataIndex: 'reminderDate',
      key: 'reminder_date',
      render: TableColumnDate,
    },
    {
      title: t('professionalReport.negativeData.bailiffsInformation.recoverableAmount'),
      dataIndex: 'recoverableAmount',
      key: 'amount',
      render: amount => {
        return formatNumber(amount);
      },
    },
    {
      title: t('professionalReport.negativeData.bailiffsInformation.plaintiffName'),
      dataIndex: 'plaintiffName',
      key: 'plaintiff',
    },
    {
      title: t('professionalReport.negativeData.bailiffsInformation.caseNumber'),
      dataIndex: 'caseNumber',
      key: 'case_no',
    },
  ];

  const expandedRow = record => {
    return (
      <div style={{ marginLeft: '2rem' }}>
        <div>
          <b>{`${t('professionalReport.negativeData.bailiffsInformation.details.bailiff')}: `}</b>
          {record.bailiffName}
        </div>
        <div>
          <b>{`${t('professionalReport.negativeData.bailiffsInformation.details.settlementPeriod')}: `}</b>
          {record.settlementPeriod}
        </div>
        <div>
          <b>{`${t('professionalReport.negativeData.bailiffsInformation.details.institutionName')}: `}</b>
          {record.institutionName}
        </div>
        <div>
          <b>{`${t('professionalReport.negativeData.bailiffsInformation.details.executiveDocumentName')}: `}</b>
          {record.executiveDocumentName}
        </div>
        <div>
          <b>{`${t('professionalReport.negativeData.bailiffsInformation.details.executiveDocumentNumber')}: `}</b>
          {record.executiveDocumentNumber}
        </div>
        <div>
          <b>{`${t('professionalReport.negativeData.bailiffsInformation.details.source')}: `}</b>
          <a href={record.source} target="_blank">
            {record.source}
          </a>
        </div>
        <div>
          <b>{`${t('professionalReport.negativeData.bailiffsInformation.details.note')}: `}</b>
          {t('professionalReport.negativeData.bailiffsInformation.note')}
        </div>
      </div>
    );
  };
  return (
    <>
      <CompanyDataCard
        icon={<FileTextOutlined className="icon--gray" />}
        title={t('professionalReport.negativeData.bailiffsInformation.bailiffsCalls.title')}
      >
        {bailiffsRemaindersDetails.length ? (
          <Col>
            <CompanyDataRow
              type={t('startReport.negativeData.bailiffsInformation.bailiffs.count')}
              value={bailiffsRemainders?.total ?? '-'}
            />
            <CompanyDataRow
              type={t('startReport.negativeData.bailiffsInformation.bailiffs.date')}
              value={formatDate(bailiffsRemainders?.latestDate) ?? '-'}
            />
          </Col>
        ) : (
          <Row>
            <Card className="card-transparent space-top-md">
              {t('professionalReport.negativeData.bailiffsInformation.bailiffsCalls.noInformation')}
            </Card>
          </Row>
        )}
        {bailiffsRemaindersDetails?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('professionalReport.negativeData.openDebts.detailedInformation.title')}
            data={bailiffsRemaindersDetails}
            columns={bailiffsInformationColumns}
            expandable={{
              expandedRowRender: record => expandedRow(record),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <DownOutlined onClick={e => onExpand(record, e)} />
                ) : (
                  <RightOutlined onClick={e => onExpand(record, e)} />
                ),
              expandRowByClick: true,
              defaultExpandAllRows: isUiHidden,
            }}
          />
        ) : null}
      </CompanyDataCard>
    </>
  );
};

export default BailiffsInformation;
