import React from 'react';
import { useTranslation } from 'react-i18next';

import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import { Card, Row } from 'antd';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const PropertyArrestsDetails = ({ propertyArrestsDetailsData, unregisteredData = false, pdfReport }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const unregisteredColumns = [
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.nr'),
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'dateFrom',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.dateFrom);
        const dateB = new Date(b.dateFrom);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.dateTo'),
      dataIndex: 'dateTo',
      key: 'dateTo',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.dateTo);
        const dateB = new Date(b.dateTo);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  const registeredColumns = [
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.nr'),
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.date'),
      dataIndex: 'date',
      key: 'date',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  return propertyArrestsDetailsData?.length ? (
    <CollapsibleTable
      className="space-top-md"
      title={t('professionalReport.negativeData.propertyArrests.detailedInformation.title')}
      rowKey={record => record.number}
      data={propertyArrestsDetailsData}
      columns={unregisteredData ? unregisteredColumns : registeredColumns}
      isCardCollapsed
      bordered
    />
  ) : pdfReport ? (
    <Row>
      <Card className="card-transparent space-vertical-sm">{t('startReport.comapnyRating.pdf.notBought')}</Card>
    </Row>
  ) : null;
};

export default PropertyArrestsDetails;
