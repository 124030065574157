import React from 'react';
import { Row, Col, Typography, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const CompanyDataBlock = ({ companyData = {}, hasBackBtn = false, onBackBtnClick = () => {}, enablePdf }) => {
  const { t } = useTranslation();
  const { creditInfoId, name, code, status, address, nace2, nace2Code } = companyData;

  const CompanyDataRow = ({ type, value }) => (
    <Row>
      <Col xs={24} xl={10}>
        <strong>{type}</strong>
      </Col>
      <Col xs={24} xl={14}>
        {value}
      </Col>
    </Row>
  );

  return (
    <div className="space-vertical-lg">
      {!enablePdf && (
        <Row className="company-title">
          <Col>
            <Space size="middle">
              {hasBackBtn && <ArrowLeftOutlined style={{ fontSize: '30px' }} onClick={onBackBtnClick} />}
              <Title level={2} className="space-bottom-none">
                {name}
              </Title>
            </Space>
          </Col>
        </Row>
      )}
      <Row className="statistic-block statistic-block--company" gutter={[8, 8]}>
        <Col xs={24} sm={12}>
          <CompanyDataRow key={creditInfoId} type={t('companySearch.results.basic.ciid')} value={creditInfoId} />
          <CompanyDataRow key={name} type={t('companySearch.results.basic.companyName')} value={name} />
          <CompanyDataRow key={code} type={t('companySearch.results.basic.code')} value={code} />
        </Col>
        <Col xs={24} sm={12}>
          <CompanyDataRow key={status} type={t('companySearch.results.basic.status')} value={status} />
          <CompanyDataRow key={address} type={t('companySearch.results.basic.address')} value={address} />
          <CompanyDataRow
            key={nace2}
            type={t('companySearch.results.basic.tradeSector')}
            value={`${nace2Code} - ${nace2}`}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyDataBlock;
