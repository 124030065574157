import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TableColumnDate from 'components/reusable/TableColumnDate';
import { useIsMdOrLess } from 'hooks/dom/useMedia';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

import { Table } from 'antd';
import i18n from 'i18next';
import useNumberFormatter from '../../../../hooks/format/useNumberFormatter';

const ListOfVehiclesTable = ({ vehicleTableData }) => {
  const screenIsMdOrLess = useIsMdOrLess();
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const currentLang = useMemo(() => i18n.language.split('-')[0], [i18n]);
  const sortOpts = useMemo(() => ({ sensitivity: 'base' }), []);

  const listOfVehiclesColumns = [
    {
      title: t('professionalReport.basicData.vehicles.model'),
      dataIndex: 'model',
      key: 'listOfVehicles_model',
      sorter: (a, b) => a.model.localeCompare(b.model),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.basicData.vehicles.category'),
      dataIndex: 'category',
      key: 'listOfVehicles_category',
      width: screenIsMdOrLess ? 60 : null,
      sorter: (a, b) => a.category.localeCompare(b.category, currentLang, sortOpts),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.basicData.vehicles.monRegData'),
      dataIndex: 'registrationDate',
      key: 'listOfVehicles_regDate',
      render: TableColumnDate,
      sorter: (a, b) => a.registrationDate.localeCompare(b.registrationDate),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 90 : null,
    },
    {
      title: t('professionalReport.basicData.vehicles.propertyRights'),
      dataIndex: 'ownership',
      key: 'listOfVehicles_ownership',
      sorter: (a, b) => a.ownership.localeCompare(b.ownership, currentLang, sortOpts),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.basicData.vehicles.vehicleOwner'),
      dataIndex: 'vehicleOwner',
      key: 'listOfVehicles_vehicleOwner',
      sorter: (a, b) => {
        const aOwner = a.vehicleOwner || t('professionalReport.basicData.vehicles.vehicleOwner.owner');
        const bOwner = b.vehicleOwner || t('professionalReport.basicData.vehicles.vehicleOwner.owner');
        return aOwner.localeCompare(bOwner);
      },
      render: owner => {
        return owner ?? t('professionalReport.basicData.vehicles.vehicleOwner.owner');
      },
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.basicData.vehicles.price'),
      dataIndex: 'price',
      key: 'listOfVehicles_price',
      sorter: (a, b) => a.price - b.price,
      render: price => {
        return formatNumber(price);
      },
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.basicData.vehicles.priceAfterYear'),
      dataIndex: 'priceAfterYear',
      key: 'listOfVehicles_priceafterYear',
      sorter: (a, b) => a.priceAfterYear - b.priceAfterYear,
      render: price => {
        return formatNumber(price);
      },
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.basicData.vehicles.liquidityClass'),
      dataIndex: 'liquidityClass',
      key: 'listOfVehicles_liquidityClass',
      sorter: (a, b) =>
        a.liquidityClass && b.liquidityClass && a.liquidityClass.localeCompare(b.liquidityClass, currentLang, sortOpts),
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
    {
      title: t('professionalReport.basicData.vehicles.co2Amount'),
      dataIndex: 'co2Amount',
      key: 'listOfVehicles_co2Amount',
      sorter: (a, b) => {
        const co2A = a.co2Amount || 0;
        const co2B = b.co2Amount || 0;
        return co2A - co2B;
      },
      render: amount => {
        return amount ?? '-';
      },
      sortDirections: ['ascend', 'descend'],
      width: screenIsMdOrLess ? 60 : null,
    },
  ];

  return (
    <Table
      rowKey={record => record.id}
      bordered
      dataSource={vehicleTableData}
      columns={listOfVehiclesColumns}
      scroll={isUiHidden ? false : { x: 'max-content' }}
      pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
      showSorterTooltip={false}
    />
  );
};

export default ListOfVehiclesTable;
