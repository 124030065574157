import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tabs } from 'antd';

import { resetCompanySearch } from 'store/entities/company';
import useRedirect from 'hooks/navigation/useRedirect';
import useLastPathname from 'hooks/navigation/useLastPathname';
import useAllowedFeature from 'hooks/user/useAllowedFeature';
import IndividualSearch from 'views/Search/IndividualSearch';
import CompanySearch from 'views/Search/CompanySearch';
import LoadingSpinner from '../../components/reusable/LoadingSpinner';
import UnauthenticatedSearch from './UnauthenticatedSearch';
import UnauthenticatedWrapper from './UnauthenticatedSearch/UnauthenticatedWrapper';

const { TabPane } = Tabs;

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const lastPathname = useLastPathname();
  const { isAuthenticated, signInPending, sessionLoading } = useSelector(state => state.user);
  const { getPersonPending } = useSelector(state => state.person);
  const { getCompaniesPending } = useSelector(state => state.company);
  const isIndividualSearchAllowed = useAllowedFeature('webui_individual');

  useEffect(() => {
    return () => dispatch(resetCompanySearch());
  }, []);

  const handleTabChange = activeKey => {
    if (activeKey !== lastPathname) redirect(`/search/${activeKey}`);
  };

  return (
    <LoadingSpinner size="large" spinning={getPersonPending || getCompaniesPending}>
      {isAuthenticated ? (
        <div className="container">
          <Col span={24}>
            <Row>
              <Tabs
                defaultActiveKey={lastPathname}
                style={{ height: 'auto', width: '100%' }}
                onChange={handleTabChange}
              >
                <TabPane tab={t('companySearch.tab.companySearch')} key="company">
                  <CompanySearch />
                </TabPane>
                {isAuthenticated && isIndividualSearchAllowed ? (
                  <TabPane tab={t('individualSearch.tab')} key="individual">
                    <IndividualSearch />
                  </TabPane>
                ) : null}
                {/*<TabPane tab="Baltic CB" key="3">
              Content of Tab Pane 3
            </TabPane>
            <TabPane tab="Legal information" key="4">
              <LegalInformation />
              </TabPane>*/}
              </Tabs>
            </Row>
          </Col>
        </div>
      ) : (
        !signInPending &&
        !sessionLoading && (
          <UnauthenticatedWrapper>
            <UnauthenticatedSearch />
          </UnauthenticatedWrapper>
        )
      )}
    </LoadingSpinner>
  );
};

export default Search;
