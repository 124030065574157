import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row } from 'antd';

import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import useIsUiHidden from '../../../hooks/dom/useIsUiHidden';

const PaymentHistoryDetails = ({ paymentHistoryDetailsData, pdfReport }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const detailedInfoColumns = useMemo(
    () => [
      {
        title: t('paymentHistory.detailedInformation.claimDate'),
        dataIndex: 'claimDate',
        render: TableColumnDate,
        width: isUiHidden ? 90 : 120,
      },
      {
        title: t('paymentHistory.detailedInformation.amount'),
        dataIndex: 'amount',
      },
      {
        title: t('paymentHistory.detailedInformation.creditor'),
        dataIndex: ['creditor', 'creditorSource'],
        render: (paymentHistoryData, { creditor, creditorSource }) => {
          if (creditor === creditorSource) {
            return creditor;
          } else {
            return `${creditor} / ${creditorSource}`;
          }
        },
      },
      {
        title: t('paymentHistory.detailedInformation.claimType'),
        dataIndex: 'claimType',
      },
      {
        title: t('paymentHistory.detailedInformation.claimId'),
        dataIndex: 'claimId',
      },
    ],
    [t, TableColumnDate]
  );

  return paymentHistoryDetailsData?.length ? (
    <CollapsibleTable
      className="space-top-md"
      title={t('paymentHistory.detailedInformation.title')}
      rowKey={record => record.claimId}
      data={paymentHistoryDetailsData}
      columns={detailedInfoColumns}
      scroll={false}
      pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
      isCardCollapsed
      bordered
    />
  ) : pdfReport ? (
    <Row>
      <Card className="card-transparent space-vertical-sm">{t('startReport.comapnyRating.pdf.notBought')}</Card>
    </Row>
  ) : null;
};
export default PaymentHistoryDetails;
