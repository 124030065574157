import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { negativeDataPropertyArrests } from 'store/entities/professionalReport/selectors/negativeDataSelectors';

import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';
import PropertyArrestsCard from 'components/reusable/report/cards/PropertyArrestsCard';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const PropertyArrests = () => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();

  const { unregisteredArrests, unregisteredArrestsDetails, registeredArrests, registeredArrestsDetails } =
    useSelector(negativeDataPropertyArrests);

  const unregisteredColumns = [
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.nr'),
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.dateFrom'),
      dataIndex: 'dateFrom',
      key: 'dateFrom',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.dateFrom);
        const dateB = new Date(b.dateFrom);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.dateTo'),
      dataIndex: 'dateTo',
      key: 'dateTo',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.dateTo);
        const dateB = new Date(b.dateTo);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  const registeredColumns = [
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.nr'),
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.date'),
      dataIndex: 'date',
      key: 'date',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.negativeData.propertyArrests.detailedInformation.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  return (
    <>
      <div>
        <PropertyArrestsCard type="registered" propertyArrestsData={registeredArrests}>
          {registeredArrestsDetails?.length ? (
            <CollapsibleTable
              className="space-top-md"
              title={t('professionalReport.negativeData.propertyArrests.detailedInformation.title')}
              data={registeredArrestsDetails}
              columns={registeredColumns}
              bordered
            />
          ) : null}
        </PropertyArrestsCard>
      </div>
      <div>
        <PropertyArrestsCard propertyArrestsData={unregisteredArrests}>
          {unregisteredArrestsDetails?.length ? (
            <CollapsibleTable
              className="space-top-md"
              title={t('professionalReport.negativeData.propertyArrests.detailedInformation.title')}
              data={unregisteredArrestsDetails}
              columns={unregisteredColumns}
              bordered
            />
          ) : null}
        </PropertyArrestsCard>
      </div>
    </>
  );
};

export default PropertyArrests;
