import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useCurrentLanguage from './hooks/user/useCurrentLanguage';
import { languages } from 'i18n';
import useAuthCheck from './hooks/auth/useAuthCheck';
import useIdleSession from './hooks/user/useIdleSession';

import { ConfigProvider } from 'antd';

import AppLayout from './components/Layout';
import Router from './components/Router';
import InactiveSessionModal from 'components/Modals/InactiveSessionModal';
import { init, RouteTracker } from 'utils/general/GoogleAnalytics';

const App = () => {
  const [isSessionInactiveModalOpen, setIsSessionInactiveModalOpen] = useState(false);
  const [initialTime, setInitialTime] = useState();
  const lang = useCurrentLanguage();
  const { isAuthenticated } = useSelector(state => state.user);
  const location = useLocation();
  const isPdfReport = location?.pathname?.includes('/pdf-print');

  useIdleSession({
    onIdle: () => {
      setInitialTime(new Date());
      if (!isSessionInactiveModalOpen && isAuthenticated) setIsSessionInactiveModalOpen(true);
    },
  });

  useAuthCheck({ isPdfReport, location });

  return (
    <>
      <ConfigProvider locale={languages.find(({ codeShort }) => codeShort === lang)?.antdLocale}>
        <AppLayout>
          {init() && <RouteTracker />}
          <Router />
        </AppLayout>
      </ConfigProvider>
      {isSessionInactiveModalOpen && (
        <InactiveSessionModal initialTime={initialTime} onClose={() => setIsSessionInactiveModalOpen(false)} />
      )}
    </>
  );
};

export default App;
