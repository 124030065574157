import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import _isEmpty from 'lodash.isempty';

import useFormatDate, { DATE_FORMAT_LONG } from 'hooks/format/useFormatDate';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { CHART_MARGINS } from 'constants/chart';

import YAxisTick from './YAxisTick';
import CustomLegend from '../Legend';
import ChartWrapper from '../ChartWrapper';
import LazyLoader from '../LazyLoader';
import colors from 'constants/colors';

import { ComposedChart, XAxis, YAxis, Tooltip, Legend, Line, CartesianGrid } from 'recharts';
import useNumberFormatter from 'hooks/format/useNumberFormatter';
import { Divider } from 'antd';

const RatingClassType = {
  _0: 0,
  _1: 1,
  _2: 2,
  _3: 3,
  _4: 4,
  _5: 5,
  _6: 6,
  _7: 7,
  _8: 8,
  _9: 9,
  _10: 10,
  _99: 11,
};

const ScoringHistoryGraph = chartProps => (
  <ChartWrapper {...chartProps}>{props => <Chart {...props}></Chart>}</ChartWrapper>
);

const Chart = props => {
  const { data = [], riskClasses = [], showLegend = true, width, yAxisWidth, callbackRef, type } = props;
  const { t } = useTranslation();
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 3 });
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const tooltipData = payload[0].payload;
      const ratingClass = tooltipData.ratingClass === 11 ? 99 : tooltipData.ratingClass;

      return (
        <div className="recharts-default-tooltip" style={{ padding: 10 }}>
          <Trans
            i18nKey="professionalReport.companyRatings.tooltip"
            values={{ pd: formatNumber(tooltipData.pd), ratingClass: ratingClass }}
            components={{ bold: <strong /> }}
          />
        </div>
      );
    }

    return null;
  };

  const prepareData = data => {
    return data.map(item => {
      const date = new Date(item.scoreDate);
      const year = date.getFullYear();
      const translatedQuarter = t(`professionalReport.companyRatings.${item.quarter}`);
      return {
        ...item,
        ratingClass: RatingClassType[item.ratingClass],
        yearQuarter: item?.quarter ? `${year} ${translatedQuarter}` : t('professionalReport.companyRatings.actual'),
      };
    });
  };

  const graphColors = type === 'defaultRating' ? '#0C9CB2' : '#C40F11';

  return (
    <LazyLoader defaultValue={isUiHidden}>
      {(waypoint, isVisible) => (
        <div>
          {waypoint}
          {_isEmpty(data) && <div>{t('global.noInformation')}</div>}
          {isVisible && data.length > 0 && (
            <>
              <Divider className="space-vertical-sm" />
              <div className="ratingsHistoryChart" ref={callbackRef}>
                <ComposedChart
                  width={width}
                  height={400}
                  data={prepareData(data)}
                  margin={{ ...CHART_MARGINS, top: 50 }}
                >
                  <defs>
                    <linearGradient id="gradientAxis" gradientTransform="rotate(90)">
                      <stop offset="0%" stopColor="rgb(51, 178, 2)" stopOpacity="1" />
                      <stop offset="50%" stopColor="rgb(255,245,0)" stopOpacity="1" />
                      <stop offset="82%" stopColor="rgb(255, 0, 0)" stopOpacity="1" />
                      <stop offset="100%" stopColor="black" stopOpacity="1" />
                    </linearGradient>
                  </defs>
                  <text
                    x={yAxisWidth + 55}
                    y={CHART_MARGINS.top}
                    fill="black"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                  >
                    {t('professionalReport.companyRatings.riskClasses')}
                  </text>
                  <text x={-180} y={20} transform="rotate(-90)" textAnchor="middle" alignmentBaseline="middle">
                    {t('professionalReport.companyRatings.verticalLabel')}
                  </text>
                  <rect width="30" height="300" x={yAxisWidth + 35} y="30" fill="url(#gradientAxis)" rx={5} />
                  <rect width="40" height="300" x={yAxisWidth - 30} y="30" fill="#E4F4F6" rx={5} />
                  <YAxis
                    domain={[1, 11]}
                    width={yAxisWidth + 50}
                    tick={<YAxisTick riskClasses={riskClasses} isPrint={isUiHidden} />}
                    tickCount={11}
                    interval={0}
                    tickLine={false}
                    axisLine={false}
                    padding={{ bottom: 15 }}
                    reversed
                  />
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <XAxis dataKey="yearQuarter" padding={{ left: 50, right: 30 }} />
                  <Tooltip content={<CustomTooltip formatDate={formatDate} />} />
                  {showLegend && (
                    <Legend
                      style={{ right: 20, left: null }}
                      className="test"
                      layout="vertical"
                      graphId="defaultScoringHistory"
                      content={<CustomLegend />}
                    />
                  )}
                  <Line
                    dataKey="ratingClass"
                    stroke={graphColors}
                    isAnimationActive={!isUiHidden}
                    activeDot={{
                      r: 8,
                      fill: colors.COLOR_WHITE,
                      stroke: graphColors,
                    }}
                    dot={({ cx, cy, stroke, strokeWidth }) => (
                      <circle
                        key={cx}
                        cx={cx}
                        cy={cy}
                        r={5}
                        fill={colors.COLOR_WHITE}
                        stroke={stroke}
                        strokeWidth={strokeWidth}
                      />
                    )}
                    strokeWidth={2}
                  />
                </ComposedChart>
              </div>
            </>
          )}
        </div>
      )}
    </LazyLoader>
  );
};

export default ScoringHistoryGraph;
