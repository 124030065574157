import React from 'react';
import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';

import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useFormatDate from 'hooks/format/useFormatDate';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const LegalProceedingsCard = ({ legalProceedingsData, children }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return (
    <CompanyDataCard
      icon={<WarningOutlined className="icon--gray" />}
      title={t('professionalReport.negativeData.legalProceedings.title')}
    >
      <CompanyDataRow
        type={t('professionalReport.negativeData.legalProceedings.numberOfProceedings')}
        value={legalProceedingsData?.total || '-'}
      />
      <CompanyDataRow
        type={t('professionalReport.negativeData.legalProceedings.lastDate')}
        value={legalProceedingsData?.latestDate ? formatDate(legalProceedingsData?.latestDate) : '-'}
      />
      <div className="space-top-md">{children}</div>
    </CompanyDataCard>
  );
};

export const LegalProceedingsOtherCard = ({ legalProceedingsData, children }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return (
    <CompanyDataCard
      icon={<WarningOutlined className="icon--gray" />}
      title={t('professionalReport.negativeData.legalProceedings.otherInformation.title')}
    >
      <CompanyDataRow
        type={t('professionalReport.negativeData.legalProceedings.otherInformation.numberOfEntries')}
        value={legalProceedingsData?.total || '-'}
      />
      <CompanyDataRow
        type={t('professionalReport.negativeData.legalProceedings.otherInformation.dateOfLastEntry')}
        value={legalProceedingsData?.latestDate ? formatDate(legalProceedingsData?.latestDate) : '-'}
      />
      {children}
    </CompanyDataCard>
  );
};

/**
 * @param tableData
 * @param type "asDefendant" | "asPlaintiff" | "asThirdParty"
 */
export const LegalProceedingsTable = ({ tableData, type = '', briefPdf, report = null }) => {
  const isUiHidden = useIsUiHidden();
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const detailedInfoColumns = [
    {
      title: t('professionalReport.negativeData.legalProceedings.otherInformation.detailedInformation.date'),
      dataIndex: 'date',
      width: isUiHidden ? 90 : 120,
      render: date => {
        if (!date) return null;

        return <span>{formatDate(date)}</span>;
      },
      key: 'date',
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t(
        'professionalReport.negativeData.legalProceedings.otherInformation.detailedInformation.processNumberAndCourt'
      ),
      dataIndex: ['caseNr', 'processNumber', 'caseType', 'date'],
      width: 215,
      key: 'processNumber',
      render: (claimsData, { processNumber, caseType, caseNr }) => {
        return (
          <>
            <p>{processNumber ? processNumber : '-'}</p>
            <hr />
            <p>
              {caseType} {caseNr}
            </p>
          </>
        );
      },
    },
    {
      title: t('professionalReport.negativeData.legalProceedings.detailedInformation.court'),
      dataIndex: 'court',
      key: 'court',
      sorter: (a, b) => a.court.localeCompare(b.court),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('professionalReport.negativeData.legalProceedings.detailedInformation.parties'),
      dataIndex: 'partiesCourt',
      render: partiesCourt => <p dangerouslySetInnerHTML={{ __html: partiesCourt }}></p>,
      key: 'partiesCourt',
    },
    {
      title: t('professionalReport.negativeData.legalProceedings.detailedInformation.nature'),
      dataIndex: 'nature',
      key: 'nature',
    },
    {
      title: t('professionalReport.negativeData.legalProceedings.detailedInformation.decision'),
      dataIndex: 'decision',
      key: 'decision',
      sorter: (a, b) => a.decision.localeCompare(b.decision),
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <CollapsibleTable
      title={t(`professionalReport.negativeData.legalProceedings.detailedInformation.${type}.title`)}
      disclaimer={
        briefPdf
          ? t('professionalReport.negativeData.legalProceedings.detailedInformation.briefDisclaimer')
          : report === 'start' && tableData.length > 50
          ? t('professionalReport.negativeData.legalProceedings.detailedInformation.last50')
          : null
      }
      data={briefPdf ? tableData.slice(0, 10) : report === 'start' ? tableData.slice(0, 50) : tableData}
      columns={detailedInfoColumns}
      pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
      bordered
    />
  );
};

export default LegalProceedingsCard;
