import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import { negativeDataLegalProceedings } from 'store/entities/professionalReport/selectors/negativeDataSelectors';

import CollapsibleTable from 'components/reusable/CollapsibleTable';
import TableColumnDate from 'components/reusable/TableColumnDate';

import { Row, Card } from 'antd';
import LegalProceedingsCard, {
  LegalProceedingsOtherCard,
  LegalProceedingsTable,
} from 'components/reusable/report/cards/LegalProceedingsCard';

const LegalProceedings = ({ briefPdf }) => {
  const [customReport, setCustomReport] = useState(null);
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const { customPdfValues } = useSelector(state => state.professionalReport);

  useEffect(() => {
    if (customPdfValues && 'Courts' in customPdfValues) {
      setCustomReport(customPdfValues['Courts']);
    }
  }, [customPdfValues]);

  const {
    legalProceeding,
    legalProceedingsDetailedDefendant,
    legalProceedingsDetailedPlaintiff,
    legalProceedingsDetailedThirdParty,
    legalProceedingOther,
    legalProceedingsDetailedOther,
  } = useSelector(negativeDataLegalProceedings);

  const otherInfoColumns = [
    {
      title: t('professionalReport.negativeData.legalProceedings.otherInformation.detailedInformation.date'),
      dataIndex: 'date',
      key: 'date',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
    {
      title: t('professionalReport.negativeData.legalProceedings.otherInformation.detailedInformation.court'),
      dataIndex: 'comment',
      key: 'comment',
    },
  ];

  return (
    <>
      <div id="legalProceedings">
        <LegalProceedingsCard legalProceedingsData={legalProceeding}>
          {customPdfValues && !customReport ? (
            <Row>
              <Card className="card-transparent space-vertical-sm">
                {t('professionalReport.negativeData.legalProceedings.detailedInformation.customDisclaimer')}
              </Card>
            </Row>
          ) : (
            <div className="space-top-md">
              <LegalProceedingsTable
                briefPdf={briefPdf}
                tableData={legalProceedingsDetailedDefendant}
                type="asDefendant"
              />
              <LegalProceedingsTable
                briefPdf={briefPdf}
                tableData={legalProceedingsDetailedPlaintiff}
                type="asPlaintiff"
              />
              <LegalProceedingsTable
                briefPdf={briefPdf}
                tableData={legalProceedingsDetailedThirdParty}
                type="asThirdParty"
              />
              {briefPdf && (
                <Row>
                  <Card className="card-transparent space-vertical-sm">
                    {t(
                      'professionalReport.negativeData.legalProceedings.otherInformation.detailedInformation.briefDisclaimer'
                    )}
                  </Card>
                </Row>
              )}
            </div>
          )}
        </LegalProceedingsCard>
      </div>
      <div id="legalProceedingsOtherInfo">
        <LegalProceedingsOtherCard legalProceedingsData={legalProceedingOther}>
          <CollapsibleTable
            className="space-top-md"
            title={t('professionalReport.negativeData.legalProceedings.otherInformation.detailedInformation.title')}
            data={legalProceedingsDetailedOther}
            columns={otherInfoColumns}
            pagination={isUiHidden ? false : { defaultPageSize: 10, showSizeChanger: true }}
            bordered
          />
        </LegalProceedingsOtherCard>
      </div>
    </>
  );
};

export default LegalProceedings;
