import { createAsyncThunk } from '@reduxjs/toolkit';
import administrationService from 'api/administrationService';
import { formatISO } from 'date-fns';

export const addDebtorCompany = createAsyncThunk(
  'company/administration/debts/add',
  async (requestBody, { rejectWithValue }) => {
    try {
      return await administrationService.addCompanyDebtor(requestBody);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addDebtorPerson = createAsyncThunk(
  'person/administration/debts/add',
  async (requestBody, { rejectWithValue }) => {
    try {
      return await administrationService.addPersonDebtor(requestBody);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDebtId = createAsyncThunk(
  'company/administration/debts/generated-debtId',
  async (requestBody, { rejectWithValue }) => {
    try {
      return await administrationService.getDebtId(requestBody);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDebtTypes = createAsyncThunk(
  'type/administration/debts/types',
  async ({ companyOrPerson }, { rejectWithValue }) => {
    try {
      return await administrationService.getDebtTypes({ companyOrPerson });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCompanyOrPersonUncoveredDebtsList = createAsyncThunk(
  'type/administration/debts/uncovered',
  async (params, { rejectWithValue }) => {
    try {
      return await administrationService.getCompanyOrPersonUncoveredDebtsList(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCompanyOrPersonCoveredDebtsList = createAsyncThunk(
  'type/administration/debts/covered',
  async (params, { rejectWithValue }) => {
    try {
      return await administrationService.getCompanyOrPersonCoveredDebtsList(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDebtorInformation = createAsyncThunk(
  'type/administration/debts/list',
  async ({ companyCode }, { rejectWithValue }) => {
    try {
      return await administrationService.getDebtorInformation({ companyCode });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDebtPayment = createAsyncThunk(
  '/administration/debts/payment/update',
  async ({ paymentDate, caseNr, amount, id, companyOrPerson }, { rejectWithValue }) => {
    try {
      return await administrationService.updateDebtPayment({
        paymentDate: formatISO(new Date(paymentDate)),
        caseNr,
        amount,
        id,
        companyOrPerson,
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDebtAmount = createAsyncThunk(
  '/administration/debts',
  async ({ amount, id, companyOrPerson }, { rejectWithValue }) => {
    try {
      return await administrationService.updateDebtAmount({
        amount,
        id,
        companyOrPerson,
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDebtsExcel = createAsyncThunk('/administration/getDebtsExcel', async (value, { rejectWithValue }) => {
  try {
    return await administrationService.getDebtsExcel(value);
  } catch (err) {
    return rejectWithValue(err);
  }
});
