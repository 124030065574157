import { FileOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table } from 'antd';
import CompanyDataCard from 'components/reusable/CompanyDataCard';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TableColumnDate from 'components/reusable/TableColumnDate';
import { useDispatch, useSelector } from 'react-redux';
import { riskClassesMap } from '../../../constants/otherProducts';
import useFormatDate, { DATE_FORMAT_LONG } from '../../../hooks/format/useFormatDate';
import useNumberFormatter from '../../../hooks/format/useNumberFormatter';
import CustomExpandIcon from '../../../components/reusable/report/tables/CustomExpandIcon';
import CollapsibleCard from '../../../components/reusable/CollapsibleCard';
import Paragraph from 'antd/es/typography/Paragraph';
import { DownloadExcel } from '../../../components/reusable/report/buttons';
import { getMonitoringEventsExcel } from '../../../store/entities/otherProducts/thunks';

const AlertsTable = ({ legalEntities }) => {
  const { t } = useTranslation();
  const isUiHidden = useIsUiHidden();
  const formatDate = useFormatDate();
  const formatCurrency = useNumberFormatter();
  const dispatch = useDispatch();
  const formatNumber = useNumberFormatter({ style: 'decimal' });
  const { monitoringEventsData, monitoringDatesSelected } = useSelector(state => state.otherProducts);

  const monitoringAlertColumns = [
    {
      title: t('monitoring.alerts.report.type'),
      dataIndex: 'reportType',
      key: 'type',
      sorter: (a, b) => a.reportType.localeCompare(b.reportType),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t(legalEntities ? 'monitoring.alerts.companyCode' : 'monitoring.alerts.individualCode'),
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t(legalEntities ? 'monitoring.alerts.companyName' : 'monitoring.alerts.individualName'),
      dataIndex: 'name',
      key: 'alertName',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('monitoring.alerts.risk.group'),
      dataIndex: 'riskGroupName',
      key: 'group',
      sorter: (a, b) => riskClassesMap[a.riskGroupName] - riskClassesMap[b.riskGroupName],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('monitoring.alerts.date'),
      dataIndex: 'date',
      key: 'date',
      render: TableColumnDate,
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      },
      sortDirections: ['ascend', 'descend'],
      width: isUiHidden ? 90 : 120,
    },
  ];

  const fromDate = new Date(monitoringDatesSelected?.dateFrom).toLocaleDateString();
  const toDate = new Date(monitoringDatesSelected?.dateTo).toLocaleDateString();

  const title =
    fromDate && toDate && fromDate === toDate && fromDate !== 'Invalid Date'
      ? `${t('otherProducts.WebMonitoring.alerts')} ${fromDate}`
      : fromDate && toDate && fromDate !== 'Invalid Date'
      ? `${t('otherProducts.WebMonitoring.alerts')} ${fromDate} - ${toDate}`
      : t('otherProducts.WebMonitoring.alerts');

  const semiBoldTag = text => {
    return <span style={{ fontWeight: 600 }}>{text}</span>;
  };

  const MonitoringRow = ({ type, value1, value2, noBold = false }) => (
    <Row gutter={[24, 0]}>
      {noBold ? (
        <Col xs={24}>{type}</Col>
      ) : (
        <Col xs={24} md={8} lg={10} xl={8} xxl={6} style={{ maxWidth: 340 }}>
          {semiBoldTag(type)}:
        </Col>
      )}
      <Col xs={24} md={16} lg={14} xl={16} xxl={18}>
        {value1}
        {value2 && <span style={{ marginLeft: 20 }}>{value2}</span>}
      </Col>
    </Row>
  );

  function linkifyText(text) {
    // Regular expression to match URLs in the text
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g;

    // Replace URLs with anchor tags
    const linkedText = text.replace(urlRegex, url => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    return linkedText;
  }

  const getShareholdersType = type => {
    switch (type) {
      case 5:
        return 'Įkūrėjas';
      case 10:
        return 'Akcininkas';
      case 20:
        return 'Individualios įmonės vadovas';
      case 30 || 32 || 35 || 36 || 37:
        return 'Dalinės nuosavybės dalyvis';
      case 40 || 42:
        return 'Akcininko teises turinčios institucijos';
      default:
        return '';
    }
  };

  const getMonitoringEvent = record => {
    if (!record || !record?.details) return <div>{t('monitoring.alerts.no.entries')}</div>;
    switch (record.type) {
      case 'DebtPayment':
        return record?.details?.map((detail, index) => (
          <React.Fragment key={index}>
            <MonitoringRow type={t(`monitoring.events.recordTitle.caseNr`)} value1={detail?.caseNr} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimDateAndPaymentDate`)}
              value1={`${formatDate(detail?.claimDate, DATE_FORMAT_LONG)} / ${formatDate(
                detail?.paymentDate,
                DATE_FORMAT_LONG
              )}`}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimTotalAmount`)}
              value1={formatCurrency(detail?.claimTotalAmount)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.totalPaidAmount`)}
              value1={formatCurrency(detail?.claimTotalAmount - detail?.totalPaidAmount ?? '')}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.claimOwnerCode`)} value1={detail?.claimOwnerCode} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.claimOwnerName`)} value1={detail?.claimOwnerName} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.saltinioName`)} value1={detail?.saltinioName} />
            {index !== record?.details?.length - 1 && <Divider />}
          </React.Fragment>
        ));
      case 'NewDebt':
        return (
          <>
            <MonitoringRow type={t(`monitoring.events.recordTitle.caseNr`)} value1={record?.details[0]?.caseNr} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimDate`)}
              value1={formatDate(record?.details[0]?.claimDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimAmount`)}
              value1={formatCurrency(record?.details[0]?.claimAmount)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.managerName`)}
              value1={record?.details[0]?.managerName}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimOwnerCode`)}
              value1={record?.details[0]?.claimOwnerCode}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimOwnerName`)}
              value1={record?.details[0]?.claimOwnerName}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.sourceName`)}
              value1={record?.details[0]?.sourceName}
            />
          </>
        );
      case 'ChangeOfLegalStatus':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.bukleChangedAt`)}
              value1={formatDate(record?.details[0]?.bukleChangedAt, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.oldValue`)} value1={record?.details[0]?.oldValue} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.newValue`)} value1={record?.details[0]?.newValue} />
          </>
        );
      case 'ChangeOfLegalAddress':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldAddressValue`)}
              value1={record?.details[0]?.oldAddressValue}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newAddressValue`)}
              value1={record?.details[0]?.newAddressValue}
            />
          </>
        );
      case 'NameChange':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldNameValue`)}
              value1={record?.details[0]?.oldNameValue}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newNameValue`)}
              value1={record?.details[0]?.newNameValue}
            />
          </>
        );
      case 'CreditLimit':
        return (
          <>
            <MonitoringRow type={t(`monitoring.events.recordTitle.newCode`)} value1={record?.details[0]?.newCode} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.oldCode`)} value1={record?.details[0]?.oldCode} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.name`)} value1={record?.details[0]?.name} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.formationDate`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.comment`)} value1={record?.details[0]?.comment} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.amount`)}
              value1={formatCurrency(record?.details[0]?.amount)}
            />
          </>
        );
      case 'IncomesDecrease':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldDateAndOldIncomesValue`)}
              value1={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
              value2={formatCurrency(record?.details[0]?.oldIncomesValue)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newDateAndNewIncomesValue`)}
              value1={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
              value2={formatCurrency(record?.details[0]?.newIncomesValue)}
            />
          </>
        );
      case 'VehiclesDecrease':
        return record.details.map((detail, index) => (
          <React.Fragment key={index}>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldVehiclesAmountValue`)}
              value1={detail?.ownershipType}
              value2={detail?.oldVehiclesAmountValue}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newVehiclesAmountValue`)}
              value1={detail?.ownershipType}
              value2={detail?.newVehiclesAmountValue}
            />
          </React.Fragment>
        ));
      case 'Report':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newCompanyCode`)}
              value1={record?.details[0]?.newCompanyCode}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldCompanyCode`)}
              value1={record?.details[0]?.oldCompanyCode}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.name`)} value1={record?.details[0]?.name} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.manager`)} value1={record?.details[0]?.manager} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.reportDate`)}
              value1={formatDate(record?.details[0]?.reportDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.reportComments`)}
              value1={record?.details[0]?.reportComments}
            />
          </>
        );
      case 'DeletionFromVATPayersList':
        return (
          <>
            <MonitoringRow type={t(`monitoring.events.recordTitle.vatCode`)} value1={record?.details[0]?.vatCode} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.vatDateFrom`)}
              value1={formatDate(record?.details[0]?.vatDateFrom, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.vatDateTo`)}
              value1={formatDate(record?.details[0]?.vatDateTo, DATE_FORMAT_LONG)}
            />
          </>
        );
      case 'PublicNegativeInformation':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.formationDate`)}
              value1={formatDate(record?.details[0]?.formationDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type="Pastabos" value1={record?.details[0]?.comments} />
          </>
        );
      case 'PressRelease':
        return (
          <>
            {semiBoldTag(record?.details[0]?.title)}
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.publishedDate`)}
              value1={formatDate(record?.details[0]?.publishedDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.channelTitle`)}
              value1={record?.details[0]?.channelTitle}
            />
            <p>
              <Trans i18nKey={linkifyText(record?.details[0]?.description)} components={{ a: <a /> }} />
            </p>
          </>
        );
      case 'FinancialStatement':
        return (
          <>
            <MonitoringRow type={t(`monitoring.events.recordTitle.statementYear`)} value1={record?.details[0]?.year} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.accountPeriodLength`)}
              value1={record?.details[0]?.accountPeriodLength}
            />
            {semiBoldTag('Pastaba: detali finansinė ataskaita apmokestinama pagal sutarties salygas')}
            <div className="space-vertical-sm">
              <a href={`/search/company/${record?.details[0]?.code}`} target="_blank">
                <Button size="small" className="btn-secondary">
                  {t('monitoring.events.record.kbsBtn')}
                </Button>
              </a>
            </div>
          </>
        );
      case 'IncreaseOfQueries':
        return (
          <Trans
            i18nKey={'monitoring.events.recordTitle.countPer6Month'}
            values={{ count: record?.details[0]?.count }}
          />
        );
      case 'BankruptcyOrRestructurisationInformation':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.iskDate`)}
              value1={formatDate(record?.details[0]?.iskDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.decisionDate`)}
              value1={formatDate(record?.details[0]?.decisionDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.terminationDate`)}
              value1={formatDate(record?.details[0]?.terminationDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.court`)} value1={record?.details[0]?.court} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.admin`)} value1={record?.details[0]?.admin} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.address`)} value1={record?.details[0]?.address} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.phoneNumber`)}
              value1={record?.details[0]?.phoneNumber}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.creditDate`)}
              value1={formatDate(record?.details[0]?.creditDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.creditPlace`)}
              value1={record?.details[0]?.creditPlace}
            />
          </>
        );
      case 'EmployersAssessment':
        return (
          <>
            <MonitoringRow noBold type={record?.details[0]?.companyCode} value1={record?.details[0]?.companyName} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldEvaluationValue`)}
              value1={record?.details[0]?.oldEvaluationValue}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newEvaluationValue`)}
              value1={record?.details[0]?.newEvaluationValue}
            />
          </>
        );
      case 'ChangesInRelatedSubjects':
        return (
          <>
            <MonitoringRow noBold type={record?.details[0]?.code} value1={record?.details[0]?.name} />
          </>
        );
      case 'ChangeOfAuthorizedCapital':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={`${formatNumber(record?.details[0]?.oldValue)} ${record?.details[0]?.oldCurrency}`}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={`${formatNumber(record?.details[0]?.newValue)} ${record?.details[0]?.newCurrency}`}
            />
          </>
        );
      case 'SignificantIncreaseOfEmployees':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
              value2={record?.details[0]?.oldValue}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
              value2={record?.details[0]?.newValue}
            />
          </>
        );
      case 'SignificantDecreaseOfEmployees':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
              value2={record?.details[0]?.oldValue}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
              value2={record?.details[0]?.newValue}
            />
          </>
        );
      case 'NewDebtSodra':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.debtDate`)}
              value1={formatDate(record?.details[0]?.debtDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.debtAmount`)}
              value1={formatCurrency(record?.details[0]?.debtAmount)}
            />
            <MonitoringRow noBold type={t('monitoring.events.recordAddition.newSodraDebt')} />
          </>
        );
      case 'DebtPaymentSodra':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={formatCurrency(record?.details[0]?.oldAmount)}
              value2={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={formatCurrency(record?.details[0]?.newAmount)}
              value2={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
            />
          </>
        );
      case 'ChangeOfManager':
        return (
          <>
            <MonitoringRow noBold type={t(`monitoring.events.recordTitle.managerChange`)} />
            <MonitoringRow noBold type={t(`monitoring.events.recordTitle.paidInformation`)} />
            <a href={`/search/company/${record?.details[0]?.code}`} target="_blank">
              <Button size="small" className="btn-secondary">
                {t('monitoring.events.record.kbsBtn')}
              </Button>
            </a>
          </>
        );
      case 'ChangeOfDefaultRiskClass':
        return (
          <>
            <MonitoringRow
              type={t('monitoring.events.recordTitle.oldClass')}
              value1={
                <Trans
                  i18nKey="monitoring.events.recordAddition.rating"
                  values={{
                    class: record?.details[0]?.oldClass,
                    probability: record?.details[0]?.oldProbability.toFixed(3),
                    className: record?.details[0]?.oldClassName,
                  }}
                />
              }
            />
            <MonitoringRow
              type={t('monitoring.events.recordTitle.newClass')}
              value1={
                <Trans
                  i18nKey="monitoring.events.recordAddition.rating"
                  values={{
                    class: record?.details[0]?.newClass,
                    probability: record?.details[0]?.newProbability.toFixed(3),
                    className: record?.details[0]?.newClassName,
                  }}
                />
              }
            />
            <div className="space-vertical-sm">
              <a href={`/search/company/${record?.details[0]?.code}`} target="_blank">
                <Button size="small" className="btn-secondary">
                  {t('monitoring.events.record.kbsBtn')}
                </Button>
              </a>
            </div>
          </>
        );
      case 'ChangeOfBankruptcyRisk':
        return (
          <>
            <MonitoringRow
              type={t('monitoring.events.recordTitle.oldClass')}
              value1={
                <Trans
                  i18nKey="monitoring.events.recordAddition.rating"
                  values={{
                    class: record?.details[0]?.oldClass,
                    probability: record?.details[0]?.oldProbability.toFixed(3),
                    className: record?.details[0]?.oldClassName,
                  }}
                />
              }
            />
            <MonitoringRow
              type={t('monitoring.events.recordTitle.newClass')}
              value1={
                <Trans
                  i18nKey="monitoring.events.recordAddition.rating"
                  values={{
                    class: record?.details[0]?.newClass,
                    probability: record?.details[0]?.newProbability.toFixed(3),
                    className: record?.details[0]?.newClassName,
                  }}
                />
              }
            />
            <div className="space-vertical-sm">
              <a href={`/search/company/${record?.details[0]?.code}`} target="_blank">
                <Button size="small" className="btn-secondary">
                  {t('monitoring.events.record.kbsBtn')}
                </Button>
              </a>
            </div>
          </>
        );
      case 'ShareholdersInformation':
        return (
          <>
            <MonitoringRow noBold type={record?.details[0]?.textToDisplay} />
            <div className="space-vertical-sm">
              <a href={`/search/company/${record?.details[0]?.code}`} target="_blank">
                <Button size="small" className="btn-secondary">
                  {t('monitoring.events.record.kbsBtn')}
                </Button>
              </a>
            </div>
          </>
        );
      case 'IncreaseOfTurnover':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
              value2={formatCurrency(record?.details[0]?.oldValue)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
              value2={formatCurrency(record?.details[0]?.newValue)}
            />
          </>
        );
      case 'ChangeOfManagerWithDateOfBirth':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.companyName`)}
              value1={record?.details[0]?.companyName}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.manager`)} value1={record?.details[0]?.manager} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.dateOfBirth`)}
              value1={formatDate(record?.details[0]?.dateOfBirth, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.fact`)} value1={record?.details[0]?.fact} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.formationDate`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
            <Divider />
            <MonitoringRow noBold type={t(`monitoring.events.recordTitle.previousManager`)} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.previousManagerName`)}
              value1={record?.details[0]?.previousManagerName}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.previousManagerDateOfBirth`)}
              value1={formatDate(record?.details[0]?.previousManagerDateOfBirth, DATE_FORMAT_LONG)}
            />
          </>
        );
      case 'ChangesInPropertySeisureInformation':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.date`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.status`)} value1={record?.details[0]?.type} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.tarNumber`)} value1={record?.details[0]?.tarNumber} />
            <div className="space-vertical-sm">
              <a href={`/search/company/${record?.details[0]?.code}`} target="_blank">
                <Button size="small" className="btn-secondary">
                  {t('monitoring.events.record.kbsBtn')}
                </Button>
              </a>
            </div>
          </>
        );
      case 'ChangeInPropertySeisureInformationWithDetails':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.date`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.status`)} value1={record?.details[0]?.type} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.tarNumber`)} value1={record?.details[0]?.tarNumber} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.tarAmountRange`)}
              value1={record?.details[0]?.amount}
            />
            <div className="space-vertical-sm">
              <a href={`/search/company/${record?.details[0]?.code}`} target="_blank">
                <Button size="small" className="btn-secondary">
                  {t('monitoring.events.record.kbsBtn')}
                </Button>
              </a>
            </div>
          </>
        );
      case 'VehiclesIncrease':
        return record.details.map((detail, index) => (
          <React.Fragment key={index}>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldVehiclesAmountValue`)}
              value1={detail?.ownershipType}
              value2={detail?.oldVehiclesAmountValue}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newVehiclesAmountValue`)}
              value1={detail?.ownershipType}
              value2={detail?.newVehiclesAmountValue}
            />
          </React.Fragment>
        ));
      case 'IncreaseOfDebtSodra':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={formatCurrency(record?.details[0]?.oldAmount)}
              value2={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={formatCurrency(record?.details[0]?.newAmount)}
              value2={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
            />
          </>
        );
      case 'IncreaseOfDebtAmount':
        return record.details.map((detail, index) => (
          <React.Fragment key={index}>
            <MonitoringRow type={t(`monitoring.events.recordTitle.caseNr`)} value1={detail?.caseNr} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimRiseDate`)}
              value1={formatDate(detail?.claimDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimAmountBefore`)}
              value1={detail?.claimAmountBefore}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.claimAmountAfter`)}
              value1={detail?.claimAmountAfter}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.claimOwnerCode`)} value1={detail?.claimOwnerCode} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.claimOwnerName`)} value1={detail?.claimOwnerName} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.sourceName`)} value1={detail?.sourceName} />
            {index !== record.details.length - 1 && <Divider />}
          </React.Fragment>
        ));
      case 'CourtInformationDefendant':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.date`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.caseNr`)}
              value1={`${record?.details[0]?.caseType} ${record?.details[0]?.caseNumber}`}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.courtName`)} value1={record?.details[0]?.courtName} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.casePoint`)} value1={record?.details[0]?.casePoint} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.members`)}
              value1={<p dangerouslySetInnerHTML={{ __html: record?.details[0]?.members }}></p>}
            />
          </>
        );
      case 'CourtInformationPlaintiff':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.date`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.caseNr`)}
              value1={`${record?.details[0]?.caseType} ${record?.details[0]?.caseNumber}`}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.courtName`)} value1={record?.details[0]?.courtName} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.casePoint`)} value1={record?.details[0]?.casePoint} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.members`)}
              value1={<p dangerouslySetInnerHTML={{ __html: record?.details[0]?.members }}></p>}
            />
          </>
        );
      case 'CourtInformationThirdParty':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.date`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.caseTypeAndNr`)}
              value1={`${record?.details[0]?.caseType} ${record?.details[0]?.caseNumber}`}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.courtName`)} value1={record?.details[0]?.courtName} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.casePoint`)} value1={record?.details[0]?.casePoint} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.members`)}
              value1={<p dangerouslySetInnerHTML={{ __html: record?.details[0]?.members }}></p>}
            />
          </>
        );
      case 'DeferredAmountPaymentToSodra':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={formatCurrency(record?.details[0]?.oldAmount)}
              value2={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={formatCurrency(record?.details[0]?.newAmount)}
              value2={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
            />
          </>
        );
      case 'IncreaseOfDeferredAmountSodra':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldValue`)}
              value1={formatCurrency(record?.details[0]?.oldAmount)}
              value2={formatDate(record?.details[0]?.oldDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newValue`)}
              value1={formatCurrency(record?.details[0]?.newAmount)}
              value2={formatDate(record?.details[0]?.newDate, DATE_FORMAT_LONG)}
            />
          </>
        );
      case 'ChangeOfManagementForIndividual':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.companyName`)}
              value1={record?.details[0]?.companyName}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.manager`)} value1={record?.details[0]?.manager} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.fact`)} value1={record?.details[0]?.fact} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.formationDate`)}
              value1={formatDate(record?.details[0]?.date, DATE_FORMAT_LONG)}
            />
          </>
        );
      case 'BankruptcyInformationOnIndividualPerson':
        return (
          <>
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.iskDate`)}
              value1={formatDate(record?.details[0]?.iskDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.dateOfMessageToCreditors`)}
              value1={formatDate(record?.details[0]?.dateOfMessageToCreditors, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.decisionDate`)}
              value1={formatDate(record?.details[0]?.decisionDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.terminationDate`)}
              value1={formatDate(record?.details[0]?.terminationDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow type={t(`monitoring.events.recordTitle.court`)} value1={record?.details[0]?.court} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.admin`)} value1={record?.details[0]?.admin} />
            <MonitoringRow type={t(`monitoring.events.recordTitle.address`)} value1={record?.details[0]?.address} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.phoneNumber`)}
              value1={record?.details[0]?.phoneNumber}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.creditDate`)}
              value1={formatDate(record?.details[0]?.creditDate, DATE_FORMAT_LONG)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.creditPlace`)}
              value1={record?.details[0]?.creditPlace}
            />
          </>
        );
      case 'ShareholdersInformationWithDetails':
        return (
          <div className="space-vertical-sm">
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.textToDisplay`)}
              value1={record?.details[0]?.textToDisplay}
            />

            <Divider />
            <MonitoringRow type={t(`monitoring.events.recordTitle.newShareholders`)} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newShareholders.name`)}
              value1={record?.details[0]?.newShareholders[0]?.name}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newShareholders.type`)}
              value1={getShareholdersType(record?.details[0]?.newShareholders[0]?.type)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newShareholders.sharesAmount`)}
              value1={`${record?.details[0]?.newShareholders[0]?.sharesAmount}%`}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.newShareholders.code`)}
              value1={record?.details[0]?.newShareholders[0]?.code}
            />

            <Divider />
            <MonitoringRow type={t(`monitoring.events.recordTitle.oldShareholders`)} />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldShareholders.name`)}
              value1={record?.details[0]?.oldShareholders[0]?.name}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldShareholders.type`)}
              value1={getShareholdersType(record?.details[0]?.oldShareholders[0]?.type)}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldShareholders.sharesAmount`)}
              value1={`${record?.details[0]?.oldShareholders[0]?.sharesAmount}%`}
            />
            <MonitoringRow
              type={t(`monitoring.events.recordTitle.oldShareholders.code`)}
              value1={record?.details[0]?.oldShareholders[0]?.code}
            />
          </div>
        );
      case 'ChangeOfDerivatives': {
        const titleYears = [
          {
            financialYear: parseInt(record?.details[0]?.currentYear),
          },
          {
            financialYear: parseInt(record?.details[0]?.yearAgo),
          },
          {
            financialYear: parseInt(record?.details[0]?.twoYearsAgo),
          },
        ];

        function convertToIncorrectFormat(correctData) {
          const incorrectData = [];

          const ratios = ['aa', 'emk', 'gsa', 'kpk', 'msa', 'np', 'nts', 'p'];

          ratios.forEach(ratio => {
            const item = {
              ratio,
            };

            item[`companies${correctData.currentYear}`] = parseFloat(correctData[ratio + 'Current']) || 0;
            item[`companies${correctData.yearAgo}`] = parseFloat(correctData[ratio + 'YearAgo']) || 0;
            item[`companies${correctData.twoYearsAgo}`] = parseFloat(correctData[ratio + 'TwoYearsAgo']) || 0;

            incorrectData.push(item);
          });

          return incorrectData;
        }
        const financialRatiosTableData = convertToIncorrectFormat(record?.details[0]);
        const financialRatiosColumns = [
          {
            title: t('monitoring.events.financialRatios.title'),
            dataIndex: 'ratio',
            render: ratio => t(`monitoring.events.financialRatios.${ratio}`),
            key: 'ratio',
          },
          ...titleYears.map(({ financialYear }) => ({
            className: 'differentFinancialYears',
            title: financialYear,
            key: 'title',
            children: [
              {
                className: 'differentFinancialYears',
                dataIndex: `companies${financialYear}`,
                key: `companies${financialYear}`,
              },
            ],
          })),
        ];

        return (
          <div className="space-vertical-sm">
            <Table
              rowKey={record => record.ratio}
              dataSource={financialRatiosTableData}
              columns={financialRatiosColumns}
              scroll={{ x: 'max-content' }}
              pagination={false}
              class
            />
            <CollapsibleCard title={t('professionalReport.financialData.financialRatios.description.title')}>
              <div className="padding-x-lg padding-top-lg">
                {financialRatiosTableData.map((item, i) => (
                  <Paragraph key={`financialRatios${i}`}>
                    <Trans
                      i18nKey={`monitoring.events.financialRatios.description.${item.ratio}`}
                      components={{ bold: <strong /> }}
                    />
                  </Paragraph>
                ))}
              </div>
            </CollapsibleCard>
          </div>
        );
      }
      default:
        return (
          <>
            {semiBoldTag(record?.reportType)} - {semiBoldTag(record?.type)}
          </>
        );
    }
  };

  return (
    <CompanyDataCard
      icon={<FileOutlined className="icon--gray" />}
      title={title}
      extra={
        <DownloadExcel
          disabled={!monitoringEventsData?.length}
          fileName={`monitoring.alerts.${legalEntities ? 'company' : 'person'}.xlsx`}
          fileDate={fromDate !== toDate ? `${fromDate} - ${toDate}` : fromDate}
          className="monitoring-excel-download"
          fetcher={() =>
            dispatch(
              getMonitoringEventsExcel({
                value: monitoringEventsData,
                type: legalEntities ? 'company' : 'person',
              })
            )
          }
        />
      }
    >
      <Table
        className="space-top-md"
        rowKey="recordId"
        bordered
        dataSource={monitoringEventsData?.map((record, index) => ({ ...record, recordId: index }))}
        columns={monitoringAlertColumns}
        rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
        pagination={
          isUiHidden
            ? false
            : {
                defaultPageSize: 20,
                showSizeChanger: monitoringEventsData?.length > 20,
                pageSizeOptions:
                  monitoringEventsData?.length > 50
                    ? ['10', '20', '50', monitoringEventsData.length]
                    : ['10', '20', '50'],
              }
        }
        expandIcon={props => CustomExpandIcon(props)}
        expandable={{
          expandedRowRender: (record, index) => getMonitoringEvent(record, index),
        }}
        expandedRowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
        showSorterTooltip={false}
      />
    </CompanyDataCard>
  );
};

export default AlertsTable;
