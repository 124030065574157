import { useIdleTimer, workerTimers } from 'react-idle-timer';

const useIdleSession = ({ onIdle }) => {
  useIdleTimer({
    timeout: 1000 * 60 * 13.5,
    onIdle,
    timers: workerTimers,
    syncTimers: 200,
    startOnMount: true,
    name: 'idle-timer',
  });
};

export default useIdleSession;
