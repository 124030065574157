import React from 'react';
import EmployeesTable from 'components/reusable/report/tables/EmployeesTable';
import CollapsibleCard from 'components/reusable/CollapsibleCard';
import AverageEmployeesChart from 'components/reusable/report/graphs/AverageEmployeesChart';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { basicEmployeesAndWages } from '../../../store/entities/startReport/selectors/basicDataSelectors';
import { BlockHeader } from '../../../components/reusable/BlockHeader';
import { ReactComponent as PeopleIcon } from '../../../assets/img/icons/people.svg';

const Employees = () => {
  const { t } = useTranslation();
  const {
    employeesNumber,
    companyEmployeesSectorsNumbers: { numberOfEmployeesInThePeriods },
  } = useSelector(basicEmployeesAndWages);

  return (
    <div className="no-break" id="employees">
      <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
        <Row justify="start" align="middle">
          <Col>
            <BlockHeader
              icon={<PeopleIcon />}
              title={t('professionalReport.basicData.employees.averageNumberOfEmployees.title')}
            />
          </Col>
        </Row>
        <AverageEmployeesChart tableData={employeesNumber} />
      </Card>
      <Card className="card-simple card-simple-padding-none space-vertical-sm has-shadow" style={{ width: '100%' }}>
        <EmployeesTable numberOfEmployeesInThePeriods={numberOfEmployeesInThePeriods ?? []} />
      </Card>
    </div>
  );
};

export default Employees;
