import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Skeleton, Card } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import useFormatDate from 'hooks/format/useFormatDate';
import useNumberFormatter from 'hooks/format/useNumberFormatter';
import CollapsibleTable from 'components/reusable/CollapsibleTable';
import { TableColumnDateMulti } from 'components/reusable/TableColumnDate';
import CompanyDataCard, { CompanyDataRow } from 'components/reusable/CompanyDataCard';
import { ReactComponent as History } from 'assets/img/icons/history.svg';
import useIsUiHidden from 'hooks/dom/useIsUiHidden';

const PaymentHistory = props => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const isUiHidden = useIsUiHidden();
  const formatNumber = useNumberFormatter({ style: 'decimal' });
  const { paidDebts, paidDebtsDetails } = props;
  const isPaidDebtDetailsNotPresent = !paidDebts || paidDebtsDetails.length === 0;

  const detailedInfoColumns = useMemo(
    () => [
      {
        title: t('paymentHistory.detailedInformation.claimDate'),
        dataIndex: 'dates',
        key: 'dates',
        render: TableColumnDateMulti,
        sorter: (a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        },
        sortDirections: ['ascend', 'descend'],
        width: isUiHidden ? 90 : 120,
      },
      {
        title: t('paymentHistory.detailedInformation.amount'),
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => a.amount.localeCompare(b.amount),
        render: price => {
          return formatNumber(price);
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: t('paymentHistory.detailedInformation.creditor'),
        dataIndex: ['creditor', 'creditorSource'],
        key: 'creditor',
        render: (paymentHistoryData, { creditor, creditorSource }) => {
          if (creditor === creditorSource) {
            return creditor;
          } else {
            return `${creditor} / ${creditorSource}`;
          }
        },
        sorter: (a, b) => a.creditorName.localeCompare(b.creditorName),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: t('paymentHistory.detailedInformation.claimType'),
        dataIndex: 'claimType',
        key: 'claimType',
        sorter: (a, b) => a.claimType.localeCompare(b.claimType),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: t('paymentHistory.detailedInformation.claimId'),
        dataIndex: 'claimId',
        key: 'claimId',
        sorter: (a, b) => a.claimId.localeCompare(b.claimId),
        sortDirections: ['ascend', 'descend'],
      },
    ],
    [t, TableColumnDateMulti]
  );

  const paymentHistoryData = useMemo(() => {
    if (isPaidDebtDetailsNotPresent) {
      return [];
    }

    return paidDebtsDetails.map(debt => {
      const { claimDate, paymentDate, amount, creditor, creditorSource, claimType, claimId, expandableInfo } = debt;

      return {
        dates: {
          claimDate: new Date(claimDate),
          paymentDate: new Date(paymentDate),
        },
        // TODO: info likely received from list of individual entities
        // expandableInfo: expandableInfo
        //   ? expandableInfo
        //   : 'Reg. no:: John Johnson| Legal address: Vilnius, VIRSULISKIU 12-15',
        amount,
        creditor,
        creditorSource,
        claimType,
        claimId,
      };
    });
  }, [paidDebtsDetails]);

  if (!paidDebts) return <Skeleton />;

  return (
    <>
      <CompanyDataCard icon={<History className="icon--gray" />} title={t('paymentHistory.title')}>
        {paidDebts?.total ? (
          <>
            <CompanyDataRow type={t('paymentHistory.numberOfPayments')} value={paidDebts.total} />
            <CompanyDataRow
              type={t('paymentHistory.latestDebtCoveringDate')}
              value={formatDate(new Date(paidDebts.latestDate))}
            />
            <CompanyDataRow
              type={t('paymentHistory.averagePaymentTerm')}
              value={<span>{`${paidDebts.averageDays} ${t('global.days')}`}</span>}
            />
          </>
        ) : (
          <Card className="card-transparent space-vertical-sm">{t('openDebts.negativeData.paidDebts.noEntries')}</Card>
        )}
        {paymentHistoryData?.length ? (
          <CollapsibleTable
            className="space-top-md"
            title={t('paymentHistory.detailedInformation.title')}
            columns={detailedInfoColumns}
            data={paymentHistoryData}
            scroll={false}
          />
        ) : null}
      </CompanyDataCard>
    </>
  );
};

export default PaymentHistory;
