import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Layout, Menu, Button } from 'antd';
import { setFeedbackModalActive } from 'store/entities/otherProducts';

import { setSiderCollapsed } from 'store/entities/ui';
import { useIsSmOrLess } from 'hooks/dom/useMedia';
import useAllowedFeature from 'hooks/user/useAllowedFeature';
import { LogoLink } from './reusable/LogoLink';
import useCurrentLanguage from '..//hooks/user/useCurrentLanguage';

import { ReactComponent as AdministrationIcon } from 'assets/img/icons/administration.svg';
import { ReactComponent as MonitoringIcon } from 'assets/img/icons/monitoring.svg';
import { ReactComponent as SpiderwebIcon } from 'assets/img/icons/spiderweb.svg';
import { ReactComponent as SearchIcon } from 'assets/img/icons/search.svg';
import { ReactComponent as ComposedChartIcon } from 'assets/img/icons/composedChart.svg';
import { ReactComponent as OldKbsIcon } from 'assets/img/icons/oldKbsIcon.svg';
import { ReactComponent as FeedbackIcon } from 'assets/img/icons/nonFiledFeedbackIcon.svg';

import {
  //BankOutlined,
  //MinusCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import useUserClaims from '../hooks/user/useUserClaims';

const { Sider } = Layout;

const Sidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { siderCollapsed } = useSelector(state => state.ui);
  const screenIsSmOrLess = useIsSmOrLess();
  const { isAuthenticated } = useSelector(state => state.user);
  const isOtherProductsAllowed = useAllowedFeature('webui_otherproducts');
  const isAdministrationAllowed = useAllowedFeature('webui_administration');

  useEffect(() => {
    if (screenIsSmOrLess) {
      dispatch(setSiderCollapsed(true));
    }
  }, [screenIsSmOrLess]);

  const getCurrentActiveItem = () => {
    const pathNameArray = location.pathname.split('/');
    return `/${pathNameArray.slice(1, 2)}`;
  };

  const otherProducts = [
    {
      permission: '/product/monitoring/',
      key: '/monitorings',
      icon: <MonitoringIcon className="icon--lg" />,
      link: '/monitorings',
      label: t('sidebar.label.otherProducts.monitorings'),
    },
    {
      permission: '/product/spiderweb/',
      key: '/spiderweb',
      icon: <SpiderwebIcon className="icon--lg" />,
      link: '/spiderweb',
      label: t('sidebar.label.otherProducts.spiderWeb'),
    },
  ];

  const permissions = useUserClaims();

  const allowedProducts = otherProducts.filter(
    product => permissions && permissions.some(item => item.key === product.permission && item.value)
  );

  const logoClassName = cx('logo', {
    'logo--collapsed': siderCollapsed,
  });

  return (
    <Sider
      width={222}
      theme="light"
      collapsible
      collapsed={siderCollapsed}
      trigger={
        <>
          {!screenIsSmOrLess && (
            <span role="button" onClick={() => dispatch(setSiderCollapsed(!siderCollapsed))}>
              {!siderCollapsed ? <LeftOutlined /> : <RightOutlined />}
            </span>
          )}
        </>
      }
      style={{ overflow: 'auto', height: '100vh', position: 'sticky', left: 0, top: 0, bottom: 0 }}
    >
      <LogoLink content={<div className={logoClassName}></div>} />
      <Menu selectedKeys={getCurrentActiveItem()} className="space-vertical-md" theme="light">
        <Menu.Item key="/search" icon={<SearchIcon className="icon--lg" />}>
          <Link to="/search">
            <span>{t('sidebar.label.search')}</span>
          </Link>
        </Menu.Item>
        {isAuthenticated && isOtherProductsAllowed && (
          <>
            {allowedProducts.map(product => (
              <Menu.Item key={product.key} icon={product.icon}>
                <Link to={product.link}>
                  <span>{product.label}</span>
                </Link>
              </Menu.Item>
            ))}
          </>
        )}
        {isAuthenticated && isAdministrationAllowed && (
          <Menu.Item key="/administration" icon={<AdministrationIcon className="icon--lg" />}>
            <Link to="/administration">
              <span>{t('sidebar.label.administration')}</span>
            </Link>
          </Menu.Item>
        )}
        {/*<Menu.Item key={`/reports`}>
          <Link to={`/reports`}>
            <MinusCircleOutlined className="icon--lg" />
            <span>{t('sidebar.label.reports')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={`/registry`}>
          <Link to={`/registry`}>
            <BankOutlined className="icon--lg" />
            <span>{t('sidebar.label.registry')}</span>
          </Link>
        </Menu.Item>
        */}
        {isAuthenticated && (
          <>
            <div className="sider-buttons">
              <a>
                {!siderCollapsed ? (
                  <Button
                    className="btn-md btn-md__secondary"
                    onClick={() => {
                      dispatch(setFeedbackModalActive(true));
                    }}
                    icon={<FeedbackIcon />}
                    style={{ width: 190, fontSize: 13 }}
                  >
                    {t('sidebar.button.feedback')}
                  </Button>
                ) : (
                  <Button className="btn-md btn-md__secondary btn-md__circle" icon={<FeedbackIcon />} />
                )}
              </a>
              <a
                href={'https://new.infobankas.lt/CreditInfoGroup_lt/foLogon.aspx?ReturnUrl=%2fcreditinfogroup_lt%2f'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {!siderCollapsed ? (
                  <Button className="btn-md" icon={<OldKbsIcon />} style={{ width: 190 }}>
                    {t('sidebar.button.oldKbs')}
                  </Button>
                ) : (
                  <Button className="btn-md btn-md__circle" icon={<OldKbsIcon />} />
                )}
              </a>
            </div>
          </>
        )}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
